import window from "assets/images/service/window.svg";
import custom from "assets/images/service/custom.svg";
import cloud from "assets/images/home/cloud.svg";
import devops from "assets/images/service/devops.svg";
import research from "assets/images/home/research.svg";
import core from "assets/images/home/core.svg";
import personalise from "assets/images/industries/personalise.svg";
import insights from "assets/images/industries/insights.svg";
import workflow from "assets/images/service/workflow.svg";
import nlp from "assets/images/service/nlp.svg";
import vision from "assets/images/service/vision.svg";
import analytic from "assets/images/service/analytics.svg";
import cloudMigration from "assets/images/service/cloudMigration.svg";
import hybrid from "assets/images/service/hybridCloud.svg";
import cloudSecurity from "assets/images/service/cloudSecurity.svg";
import service from "assets/images/home/automotive.svg";
import supply from "assets/images/industries/supply.svg";
import legacy from "assets/images/service/legacy.svg";
import agile from "assets/images/service/agile.svg";
import api from "assets/images/service/api.svg";
import mainframe from "assets/images/service/mainframe.svg";
import innovation from "assets/images/service/innovation.svg";
import customerCentric from "assets/images/service/customerCentric.svg";
import erp from "assets/images/service/erp.svg";
import collab from "assets/images/service/collab.svg";
import productivity from "assets/images/research/productivity.svg";
import bpo from "assets/images/service/bpo.svg";
import customer from "assets/images/industries/customer.svg";
import uiUx from "assets/images/service/uiUx.svg";
import robot from "assets/images/service/robot.svg";
import endToEnd from "assets/images/service/endToEnd.svg";
import maintenance from "assets/images/industries/maintenance.svg";
import disaster from "assets/images/service/disaster.svg";
import continous from "assets/images/service/continousTesting.svg";
import performance from "assets/images/service/performance.svg";
import guard from "assets/images/home/service.svg";
import lock from "assets/images/service/lock.svg";
import brain from "assets/images/home/ai.svg";

const data = [
  {
    header: {
      title: "Application Services",
      subtitle:
        "Transforming Business with Next-Generation Application Services",
      description:
        "DeepMagic provides robust application services that empower businesses to deliver seamless user experiences while optimizing operational efficiency. We help organizations design, develop, and manage applications tailored to their specific needs, ensuring scalability, security, and performance.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing application downtime by 30% for a global retailer through modernized, cloud-based application services",
    },
    hasBackground: true,
    sections: [
      {
        image: custom,
        title: "Custom Application Development",
        description:
          "Creating tailored software solutions to meet your business goals.",
      },
      {
        image: window,
        title: "Application Management",
        description:
          "Comprehensive management services that ensure your applications run smoothly and securely",
      },
      {
        image: cloud,
        title: "Application Modernization",
        description:
          "Transforming legacy applications into modern, cloud-enabled platforms.",
      },
      {
        image: devops,
        title: "DevOps Integration",
        description:
          "Automating development and operational workflows for faster time to market",
      },
    ],
  },
  {
    header: {
      title: "Artificial Intelligence",
      subtitle: "Unlock the Power of AI to Drive Business Transformation",
      description:
        "DeepMagics AI solutions enable businesses to unlock new levels of efficiency, innovation, and customer satisfaction. Our AI-powered services, from machine learning to natural language processing, help organizations make data-driven decisions and automate complex tasks.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing customer retention by 25% for a telecom company with AI-driven predictive analytics.",
    },
    sections: [
      {
        image: research,
        title: "Machine Learning",
        description:
          "Building predictive models that improve decision-making and optimize operations.",
      },
      {
        image: nlp,
        title: "Natural Language Processing (NLP)",
        description:
          "AI-driven text and speech analysis for customer service, content creation, and more.",
      },
      {
        image: vision,
        title: "Computer Vision",
        description:
          "AI solutions that analyze visual data for applications like facial recognition and object detection.",
      },
      {
        image: analytic,
        title: "Predictive Analytics",
        description:
          "AI models that anticipate trends and outcomes, driving smarter business decisions",
      },
    ],
  },
  {
    header: {
      title: "Business Process Services",
      subtitle:
        "Streamline Operations with Intelligent Business Process Services",
      description:
        "DeepMagics Business Process Services (BPS) help organizations streamline workflows and enhance operational efficiency. By leveraging automation, AI, and process reengineering, we enable businesses to focus on growth and innovation while reducing costs.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Achieving a 40% reduction in process costs for a financial services firm through AI-based process automation.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Process Automation",
        description:
          "AI-driven automation of repetitive business processes, improving efficiency.",
      },
      {
        image: personalise,
        title: "Outsourcing Services",
        description:
          "End-to-end management of business processes, from customer service to finance operations",
      },
      {
        image: workflow,
        title: "Workflow Optimization",
        description:
          "Reengineering business processes for faster execution and improved outcomes.",
      },
      {
        image: insights,
        title: "AI-Driven Insights",
        description:
          "Using AI to analyze business processes and suggest improvements for continuous optimization.",
      },
    ],
  },
  {
    header: {
      title: "Cloud Solutions",
      subtitle: "Cloud Solutions for a Connected, Scalable Future",
      description:
        "DeepMagic helps businesses leverage the power of cloud computing to enhance scalability, flexibility, and security. Our cloud solutions enable businesses to transform their infrastructure, optimize costs, and accelerate digital transformation through seamless integration of cloud platforms.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing infrastructure costs by 20% for a manufacturing firm through cloud migration and optimization",
    },
    sections: [
      {
        image: cloudMigration,
        title: "Cloud Migration",
        description:
          "Helping businesses move to the cloud seamlessly with minimal disruption.",
      },
      {
        image: cloud,
        title: "Cloud Optimization",
        description:
          "Maximizing cloud efficiency and reducing operational costs through AIpowered insights.",
      },
      {
        image: hybrid,
        title: "Hybrid and Multi-Cloud Solutions",
        description:
          "Tailored cloud architectures combining public, private, and hybrid cloud environments.",
      },
      {
        image: cloudSecurity,
        title: "Cloud Security",
        description:
          "Ensuring the security and compliance of cloud-based operations with advanced AI-powered protection.",
      },
    ],
  },
  {
    header: {
      title: "Sustainability Services",
      subtitle: "Drive Sustainability with AI-Powered Solutions",
      description: "",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing energy consumption by 15% for a retail chain through AI-powered energy efficiency solutions.",
    },
    hasBackground: true,
    sections: [
      {
        image: core,
        title: "AI for Energy Efficiency",
        description:
          "AI tools that monitor and optimize energy consumption, reducing waste and carbon footprints.",
      },
      {
        image: supply,
        title: "Sustainable Supply Chain Management",
        description:
          "Using AI to create more efficient, ecofriendly supply chains.",
      },
      {
        image: analytic,
        title: "Predictive Sustainability Analytics",
        description:
          "AI-driven tools that forecast environmental impact and provide actionable sustainability insights.",
      },
      {
        image: service,
        title: "Green Technology Integration",
        description:
          "Integrating AI with green technologies to drive sustainability initiatives.",
      },
    ],
  },
  {
    header: {
      title: "Core Modernization",
      subtitle: "Modernize Legacy Systems for Future-Ready Operations",
      description:
        "DeepMagic enables businesses to modernize their core systems, transforming legacy platforms into agile, scalable, and future-ready systems. Our core modernization services ensure that organizations can adapt to market changes, integrate emerging technologies, and enhance operational efficiency.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Improving transaction speeds by 35% for a financial institution through core system modernization",
    },
    sections: [
      {
        image: legacy,
        title: "Legacy System Modernization",
        description:
          "Transforming outdated systems into modern, cloud-enabled platforms.",
      },
      {
        image: api,
        title: "API Integration",
        description:
          "Ensuring smooth integration between modern and legacy systems through AI-enabled API services.",
      },
      {
        image: mainframe,
        title: "Mainframe Modernization",
        description:
          "Migrating mainframe-based applications to modern architectures while preserving functionality",
      },
      {
        image: agile,
        title: "Agile Modernization",
        description:
          "Implementing agile methodologies to ensure continuous improvement and adaptability.",
      },
    ],
  },
  {
    header: {
      title: "Digital Strategy",
      subtitle: "Crafting Digital Strategies that Drive Transformation",
      description:
        "DeepMagic helps businesses create forward-thinking digital strategies that integrate AI, automation, and emerging technologies. Our digital strategies enable organizations to future-proof their business models, enhance customer engagement, and achieve operational excellence",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Boosting online sales by 30% for a consumer goods company through an AI-driven digital strategy.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Digital Transformation Roadmaps",
        description:
          "Crafting strategic plans to guide businesses through digital transformation.",
      },
      {
        image: innovation,
        title: "AI-Driven Innovation",
        description:
          "Leveraging AI to redefine products, services, and customer experiences.",
      },
      {
        image: customerCentric,
        title: "Customer-Centric Digital Experiences",
        description:
          "Designing digital experiences that enhance customer engagement and loyalty.",
      },
      {
        image: service,
        title: "Technology Integration",
        description:
          "Seamless integration of AI, IoT, cloud, and other emerging technologies.",
      },
    ],
  },
  {
    header: {
      title: "Enterprise Platform Services",
      subtitle: "Unlocking Value with Scalable Enterprise Platform Services",
      description:
        "DeepMagics Enterprise Platform Services enable businesses to optimize their enterprise systems for scalability, flexibility, and performance. We design, implement, and manage enterprise platforms that meet business goals and improve collaboration across departments.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Improving operational efficiency by 25% for a multinational firm through AI-enhanced ERP solutions.",
    },
    sections: [
      {
        image: erp,
        title: "Enterprise Resource Planning (ERP)",
        description:
          "Implementation and customization of ERP solutions for improved decision-making and operational control.",
      },
      {
        image: bpo,
        title: "Customer Relationship Management (CRM)",
        description:
          "AI-powered CRM solutions that optimize customer interactions and drive sales.",
      },
      {
        image: collab,
        title: "Collaboration Platforms",
        description:
          "Enabling seamless collaboration across teams with AI-enhanced enterprise platforms.",
      },
      {
        image: productivity,
        title: "Data Management Platforms",
        description:
          "Building robust platforms for managing, analyzing, and securing business data.",
      },
    ],
  },
  {
    header: {
      title: "Experience",
      subtitle:
        "Deliver Seamless, Personalized Experiences Across All Touchpoints",
      description:
        "DeepMagic empowers businesses to create engaging, personalized experiences across digital and physical channels. Our AI-powered experience services help you understand customer behavior, personalize interactions, and build long-lasting relationships with your customers.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing customer engagement by 35% for an e-commerce brand through AI-driven CX design and omnichannel solutions.",
    },
    hasBackground: true,
    sections: [
      {
        image: customer,
        title: "Customer Experience (CX) Design",
        description:
          "AI-driven insights to create personalized, data-driven customer experiences.",
      },
      {
        image: uiUx,
        title: "User Interface & Experience (UI/UX) Optimization",
        description:
          "Improving user engagement and satisfaction through AI-enhanced design and functionality.",
      },
      {
        image: supply,
        title: "Omnichannel Solutions",
        description:
          "Delivering seamless customer experiences across all channels, including mobile, web, and in-store.",
      },
      {
        image: bpo,
        title: "Voice and Chat AI",
        description:
          "Enhancing customer support with AI-powered virtual assistants and chatbots.",
      },
    ],
  },
  {
    header: {
      title: "Infrastructure Services",
      subtitle: "Build a Resilient and Future-Proof IT Infrastructure",
      description:
        "DeepMagics Infrastructure Services help businesses design, build, and manage a future-proof IT infrastructure that scales with their growth. We use AI to optimize performance, improve security, and ensure the seamless operation of IT environments.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing IT downtime by 40% for a logistics company through AI-powered infrastructure automation.",
    },
    sections: [
      {
        image: cloud,
        title: "Cloud Infrastructure Management",
        description:
          "Managing cloud infrastructure for improved scalability, security, and performance.",
      },
      {
        image: research,
        title: "Network Optimization",
        description:
          "AI-powered tools to optimize network performance and reduce downtime.",
      },
      {
        image: service,
        title: "IT Operations Automation",
        description:
          "Automating IT processes for faster, more reliable infrastructure management.",
      },
      {
        image: disaster,
        title: "Disaster Recovery & Business Continuity",
        description:
          "AI-based solutions for safeguarding data and ensuring uninterrupted operations.",
      },
    ],
  },
  {
    header: {
      title: "Intelligent Process Automation",
      subtitle: "Automate, Optimize, and Transform Business Operations",
      description:
        "DeepMagic provides Intelligent Process Automation (IPA) services that help businesses automate complex workflows, optimize efficiency, and reduce operational costs. Our AI-driven IPA solutions integrate seamlessly into existing systems to drive operational transformation.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Cutting process cycle times by 50% for a financial institution through AI-driven intelligent automation.",
    },
    hasBackground: true,
    sections: [
      {
        image: robot,
        title: "Robotic Process Automation (RPA)",
        description:
          "Automating repetitive tasks and processes with AI-powered bots.",
      },
      {
        image: service,
        title: "Cognitive Automation",
        description:
          "Enhancing process automation with AI and machine learning for more complex, intelligent workflows.",
      },
      {
        image: endToEnd,
        title: "End-to-End Workflow Automation",
        description:
          "Automating entire business processes from start to finish, including approvals and data management.",
      },
      {
        image: analytic,
        title: "AI-Powered Analytics",
        description:
          "Leveraging AI to analyze and improve automated workflows for continuous optimization.",
      },
    ],
  },
  {
    header: {
      title: "Internet of Things (IoT)",
      subtitle:
        "Connecting Devices for Smarter Operations with AI-Driven IoT Solutions",
      description:
        "DeepMagics IoT solutions connect devices, sensors, and machines to gather real-time data, drive automation, and enhance decision-making. By combining IoT with AI, we enable smarter, more efficient operations across industries.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing maintenance costs by 20% for a manufacturing firm through AI-powered predictive maintenance using IoT data.",
    },
    sections: [
      {
        image: analytic,
        title: "IoT Data Analytics",
        description:
          "Leveraging AI to analyze data from IoT devices and extract actionable insights.",
      },
      {
        image: maintenance,
        title: "Smart Device Integration",
        description:
          "Connecting and managing devices across platforms for seamless IoT operations.",
      },
      {
        image: maintenance,
        title: "Predictive Maintenance",
        description:
          "Using AI-driven IoT data to predict equipment failures and schedule proactive maintenance.",
      },
      {
        image: service,
        title: "IoT Security",
        description:
          "Ensuring the security of connected devices with AI-powered threat detection.",
      },
    ],
  },
  {
    header: {
      title: "Quality Engineering & Assurance",
      subtitle:
        "Ensuring Quality and Performance with AI-Driven Engineering and Assurance Services",
      description:
        "DeepMagics Quality Engineering & Assurance services help businesses deliver high-quality products with reduced time to market. Using AI-driven testing and quality assurance solutions, we ensure that software and systems are reliable, secure, and optimized for performance",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing software testing time by 30% for a tech company with AI-powered test automation.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "AI-Powered Testing",
        description:
          "Using AI to automate software testing and identify potential issues before deployment.",
      },
      {
        image: performance,
        title: "Performance Engineering",
        description:
          "Optimizing systems for performance, scalability, and reliability using AIbased tools.",
      },
      {
        image: continous,
        title: "Continuous Testing",
        description:
          "Integrating continuous testing throughout the software development lifecycle for realtime feedback and improvements.",
      },
      {
        image: service,
        title: "Test Automation",
        description:
          "Automating functional and nonfunctional testing to accelerate release cycles.",
      },
    ],
  },
  {
    header: {
      title: "Security",
      subtitle: "Protect Your Business with AI-Powered Security Solutions",
      description:
        "DeepMagic provides advanced AI-driven security services that protect businesses from cyber threats, ensure regulatory compliance, and safeguard sensitive data. Our security solutions leverage AI to identify, prevent, and respond to security risks in real time.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing security breaches by 40% for a financial services firm through AI-powered threat detection.",
    },
    sections: [
      {
        image: guard,
        title: "AI-Powered Threat Detection",
        description:
          "Identifying and mitigating security threats using advanced AI algorithms.",
      },
      {
        image: cloudSecurity,
        title: "Security Information and Event Management (SIEM)",
        description:
          "AI-enabled monitoring of security events for faster incident response.",
      },
      {
        image: lock,
        title: "Data Protection & Compliance",
        description:
          "Ensuring data privacy and regulatory compliance with AI-driven security tools.",
      },
      {
        image: service,
        title: "Vulnerability Management",
        description:
          "Using AI to identify and remediate vulnerabilities in IT systems.",
      },
    ],
  },
  {
    header: {
      title: "Software Engineering",
      subtitle: "Building Future-Ready Software Solutions with AI at the Core",
      description:
        "DeepMagics Software Engineering services help businesses design, develop, and deploy custom software solutions that drive innovation and business growth. We use AI to enhance software development processes, improve quality, and accelerate time to market.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing development time by 25% for a healthcare company with AI-enhanced software engineering practices.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Custom Software Development",
        description:
          "Designing and building tailored software solutions to meet unique business needs.",
      },
      {
        image: personalise,
        title: "Agile Development",
        description:
          "Using agile methodologies to ensure flexibility and fast iterations.",
      },
      {
        image: brain,
        title: "AI-Enhanced Development",
        description:
          "Leveraging AI to improve code quality, optimize development workflows, and reduce time to market.",
      },
      {
        image: service,
        title: "DevOps Integration",
        description:
          "Integrating development and operations teams to streamline software delivery and ensure continuous improvement.",
      },
    ],
  },
];

export default data;
