import React from "react";
import style from "./scss/footer.module.scss";
// import logo from "assets/images/IOCLogo.png";
import footerMap from "assets/images/footerMap.png";
import fb from "assets/images/FacebookLogo.svg";
import insta from "assets/images/InstagramLogo.svg";
import linkedIn from "assets/images/LinkedinLogo.svg";
import { Image, Grid } from "semantic-ui-react";

const Footer = () => {
  return (
    <>
      <Grid className={style.footer}>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="left"
        >
          <div className={style.footerDiv1}>
            <div className={style.SchoolAge}>
              {/* <div className={style.ioacContent}>
                <p>SchoolAge</p>
              </div> */}
              {/* <div className={style.ioacImage}>
                <Image className={style.logoImg} src={logo} />
              </div> */}
            </div>
            <div className={style.footerDiv2}>
              <div className={style.socialMedia}>CONTACT</div>
              <div className={style.clr10}></div>
              <span>+91 9360954237</span>
              <div>
                <span>info@deepmagic.in</span>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="center"
        >
          <div className={style.footerDiv2}>
            <div className={style.clr15}></div>
            <div className={style.socialMedia}>SOCIAL MEDIA</div>
            <div className={style.socialMediaLogo}>
              <div className={style.logo}>
                <Image src={fb} />
              </div>
              <div className={style.logo}>
                <Image src={insta} />
              </div>
              <div className={style.logo}>
                <Image src={linkedIn} />
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <div className={style.footerDiv3}>
            <div className={style.visitUs}>
              VISIT US
              <p className={style.visitContent}>
                Deep Magic HQ, 123 to 127, Kattoor Street, Gandhipuram, <br />
                Coimbatore- 641037
              </p>
            </div>
            <div>
              <Image className={style.footerMap} size="small" src={footerMap} />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default Footer;
