import research from "assets/images/home/research.svg";
import settings from "assets/images/industries/settings.svg";
import magnify from "assets/images/industries/magnify.svg";
import customer from "assets/images/industries/customer.svg";
import risk from "assets/images/industries/risk.svg";
import personalise from "assets/images/industries/personalise.svg";
import check from "assets/images/industries/check.svg";
import marine from "assets/images/industries/marine.svg";
import environment from "assets/images/industries/environment.svg";
import supply from "assets/images/industries/supply.svg";
import maintenance from "assets/images/industries/maintenance.svg";
import cloud from "assets/images/home/cloud.svg";
import insights from "assets/images/industries/insights.svg";
import ad from "assets/images/industries/ad.svg";
import support from "assets/images/industries/support.svg";
import automotive from "assets/images/home/automotive.svg";
import core from "assets/images/home/core.svg";
import route from "assets/images/industries/route.svg";
import economy from "assets/images/home/economy.svg";
import chatbot from "assets/images/industries/chatbot.svg";
import sustain from "assets/images/industries/sustain.svg";

const data = [
  {
    header: {
      title: "Automotive",
      subtitle: "AI Solutions Driving the Future of the Automotive Industry",
      description:
        "The automotive industry is experiencing a transformation with AI innovations at its core. DeepMagic harnesses AI to optimize vehicle production, improve safety, and create a more connected, automated driving experience.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing production downtime by 30% for a global automaker through predictive maintenance",
    },

    hasBackground: true,

    sections: [
      {
        image: research,
        title: "Autonomous Driving",
        description:
          "AI algorithms for self-driving vehicles, integrating real-time data from sensors to navigate complex environments.",
      },
      {
        image: settings,
        title: "Predictive Maintenance",
        description:
          "AI solutions that monitor vehicle components and predict maintenance needs, reducing downtime and operational costs.",
      },
      {
        image: magnify,
        title: "Manufacturing Optimization",
        description:
          "AI-powered automation in assembly lines, enhancing production efficiency and quality control.",
      },
      {
        image: customer,
        title: "Customer Experience",
        description:
          "Personalized marketing and customer services using AI to enhance satisfaction and loyalty.",
      },
    ],
  },
  {
    header: {
      title: "Banking",
      subtitle: "AI-Driven Transformation for the Banking Industry",
      description:
        "AI is revolutionizing banking by providing data-driven insights, enhancing customer service, and increasing fraud detection accuracy. DeepMagic delivers secure and innovative AI solutions that help banks modernize their services and improve operational efficiency.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing fraud cases by 40% for a major financial institution with AI-powered transaction monitoring",
    },
    sections: [
      {
        image: magnify,
        title: "Fraud Detection",
        description:
          "Advanced AI models that analyze transaction patterns and detect fraudulent activities in real time.",
      },
      {
        image: risk,
        title: "Risk Management",
        description:
          "AI-driven risk analysis tools that assess creditworthiness and market volatility, helping banks manage risks more effectively.",
      },
      {
        image: personalise,
        title: "Customer Personalization",
        description:
          "AI-powered chatbots and recommendation engines that provide tailored customer experiences.",
      },
      {
        image: check,
        title: "Regulatory Compliance",
        description:
          "AI solutions that help banks stay compliant with changing regulations by automating reporting and monitoring systems.",
      },
    ],
  },
  {
    header: {
      title: "Blue Economy",
      subtitle: "AI Solutions for a Sustainable Blue Economy",
      description:
        "DeepMagic’s AI technologies support the sustainable development of ocean resources by optimizing marine operations, improving environmental monitoring, and fostering sustainable fisheries management.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Using AI to track and reduce overfishing, enhancing sustainability for a major fishing company.",
    },
    hasBackground: true,
    defaultBg: "style.pageWrapperBg7",
    sections: [
      {
        image: marine,
        title: "Marine Resource Management",
        description:
          "AI-powered tools for tracking and managing marine ecosystems and promoting sustainable fishing practices.",
      },
      {
        image: environment,
        title: "Environmental Monitoring",
        description:
          "AI systems that monitor oceanic conditions, helping prevent pollution and optimize resource usage.",
      },
      {
        image: supply,
        title: "Supply Chain Optimization",
        description:
          "AI solutions that enhance maritime logistics, improving efficiency in shipping and reducing carbon footprints.",
      },
      {
        image: maintenance,
        title: "Predictive Maintenance for Vessels",
        description:
          "AI models that track the health of marine vessels, reducing breakdowns and improving safety.",
      },
    ],
  },
  {
    header: {
      title: "Capital Markets",
      subtitle: "AI in Capital Markets: Empowering Smarter Investments",
      description:
        "DeepMagic offers AI tools that transform capital markets by optimizing trading systems, managing risks, and delivering predictive analytics for better investment decisions.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing portfolio returns by 20% for an asset management firm through AI-driven investment strategies.",
    },
    sections: [
      {
        image: research,
        title: "AI-Based Trading",
        description:
          "Algorithms that analyze market patterns and provide high-speed, real-time trading decisions.",
      },
      {
        image: cloud,
        title: "Predictive Analytics",
        description:
          "AI models that forecast market trends, helping investors make informed decisions.",
      },
      {
        image: personalise,
        title: "Risk Management",
        description:
          "AI solutions for detecting financial risks, enabling real-time adjustments in investment portfolios.",
      },
      {
        image: insights,
        title: "Customer Insights",
        description:
          "AI systems that offer personalized investment recommendations based on behavioral data.",
      },
    ],
  },
  {
    header: {
      title: "Communications, Media & Technology",
      subtitle:
        "AI Solutions Shaping the Future of Communications, Media & Technology",
      description:
        "The communications, media, and technology industries are being transformed by AI, enabling faster content creation, personalized user experiences, and efficient network management. DeepMagic provides cutting-edge AI solutions that streamline operations and drive innovation.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Boosting ad conversion rates by 35% for a major media platform through AI-based ad targeting.",
    },
    hasBackground: true,
    sections: [
      {
        image: personalise,
        title: "Content Creation & Personalization",
        description:
          "AI-driven content generation and recommendation engines tailored to user preferences.",
      },
      {
        image: research,
        title: "Network Optimization",
        description:
          "AI models that enhance bandwidth allocation, improving network performance and reliability.",
      },
      {
        image: ad,
        title: "Ad Targeting",
        description:
          "AI-powered tools for optimizing media campaigns with better audience segmentation and real-time insights.",
      },
      {
        image: support,
        title: "Customer Support Automation",
        description:
          "AI chatbots that handle customer inquiries, reducing response times and improving satisfaction.",
      },
    ],
  },
  {
    header: {
      title: "Consumer Goods",
      subtitle: "AI-Powered Innovation in the Consumer Goods Sector",
      description:
        "DeepMagic enables consumer goods companies to stay competitive by leveraging AI to optimize supply chains, predict consumer behavior, and personalize the customer experience. AI is reshaping the way goods are produced, marketed, and sold.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing inventory costs by 25% for a consumer goods company through AI-powered demand forecasting.",
    },
    sections: [
      {
        image: research,
        title: "Demand Forecasting",
        description:
          "AI models that analyze trends and predict demand, optimizing inventory levels and minimizing waste.",
      },
      {
        image: cloud,
        title: "Customer Personalization",
        description:
          "AI systems that create personalized marketing campaigns based on consumer behavior analysis.",
      },
      {
        image: personalise,
        title: "Supply Chain Efficiency",
        description:
          "AI tools that streamline logistics and reduce operational costs, ensuring goods are delivered faster and more efficiently.",
      },
      {
        image: automotive,
        title: "Product Innovation",
        description:
          "AI-driven insights into market trends, enabling faster product development and innovation.",
      },
    ],
  },
  {
    header: {
      title: "Education",
      subtitle: "AI-Driven Innovation in Education for Personalized Learning",
      description:
        "DeepMagic transforms the education sector with AI-based personalized learning, intelligent assessment tools, and virtual assistants, enhancing the student experience and improving educational outcomes.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing student engagement by 40% in an online learning platform through AI-driven personalized learning experiences.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Personalized Learning",
        description:
          "AI systems that adapt educational content to individual learning styles and progress.",
      },
      {
        image: personalise,
        title: "Intelligent Assessment",
        description:
          "AI-powered tools that evaluate student performance, providing real-time feedback and insights.",
      },
      {
        image: cloud,
        title: "Virtual Tutors",
        description:
          "AI-based tutoring systems that assist students with learning, available anytime, anywhere.",
      },
      {
        image: automotive,
        title: "Administrative Automation",
        description:
          "AI tools that streamline school and university administrative tasks, improving efficiency.",
      },
    ],
  },
  {
    header: {
      title: "Healthcare",
      subtitle: "AI-Powered Healthcare: Enhancing Patient Care and Outcomes",
      description:
        "DeepMagic helps healthcare providers improve diagnostics, personalize treatment, and streamline hospital operations through AI-driven insights and automation.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing diagnostic errors by 30% for a healthcare provider with AI-based imaging analysis.",
    },
    sections: [
      {
        image: research,
        title: "AI Diagnostics",
        description:
          "AI models that analyze medical images and data, improving diagnostic accuracy and reducing human error.",
      },
      {
        image: cloud,
        title: "Personalized Medicine",
        description:
          "AI-driven tools that tailor treatments to individual patients based on genetic, environmental, and lifestyle factors.",
      },
      {
        image: personalise,
        title: "Operational Efficiency",
        description:
          "AI tools that optimize hospital workflows, reducing wait times and improving patient outcomes.",
      },
      {
        image: automotive,
        title: "Predictive Analytics",
        description:
          "AI systems that forecast patient needs and health outcomes, enabling proactive care.",
      },
    ],
  },
  {
    header: {
      title: "Information Services",
      subtitle:
        "AI for Information Services: Empowering Data Driven Decision Making",
      description:
        "Information services are critical to modern business operations, and DeepMagic provides AI-powered tools that automate data collection, enhance analytics, and improve decision-making.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing data processing time by 50% for a global information services firm through AI automation.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Data Automation",
        description:
          "AI systems that automate data extraction, cleansing, and analysis, providing faster access to critical insights.",
      },
      {
        image: personalise,
        title: "Predictive Analytics",
        description:
          "AI models analyze trends and forecasts to support business strategies.",
      },
      {
        image: cloud,
        title: "Intelligent Search",
        description:
          "AI-powered search tools that retrieve relevant information quickly and accurately from vast datasets.",
      },
      {
        image: automotive,
        title: "Data Security",
        description:
          "AI solutions for securing information systems, protecting against data breaches and cyber threats.",
      },
    ],
  },
  {
    header: {
      title: "Insurance",
      subtitle:
        "AI Solutions for the Insurance Industry: Enhancing Risk Management and Claims Processing",
      description:
        "AI is transforming the insurance sector by streamlining operations, improving customer service, and optimizing risk management. DeepMagic delivers AI-driven solutions that improve efficiency across the insurance value chain.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Improving claims processing efficiency by 35% for an insurance provider through AI automation.",
    },
    sections: [
      {
        image: research,
        title: "Cloud Infrastructure Automated Claims Processing",
        description:
          "AI systems that assess and process claims in real time, reducing delays and improving accuracy.",
      },
      {
        image: cloud,
        title: "Fraud Detection",
        description:
          "AI models that analyze patterns to detect and prevent fraudulent claims.",
      },
      {
        image: personalise,
        title: "Risk Analysis",
        description:
          "AI-powered tools that assess risk profiles and optimize insurance products.",
      },
      {
        image: automotive,
        title: "Customer Personalization",
        description:
          "AI solutions that tailor insurance policies to individual customer needs, improving satisfaction.",
      },
    ],
  },
  {
    header: {
      title: "Life Sciences",
      subtitle:
        "AI Solutions for Life Sciences: Advancing Research and Innovation",
      description:
        "DeepMagic helps life sciences companies accelerate drug development, optimize clinical trials, and advance biotech innovations with AI-powered tools that drive precision and efficiency.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing drug development time by 20% for a pharmaceutical company through AI-assisted research.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Drug Discovery",
        description:
          "AI algorithms that analyze vast datasets to identify promising compounds and accelerate drug development.",
      },
      {
        image: personalise,
        title: "Clinical Trial Optimization",
        description:
          "AI systems that streamline patient recruitment and data analysis in clinical trials.",
      },
      {
        image: cloud,
        title: "Genomics and Biotechnology",
        description:
          "AI models that analyze genetic data for personalized treatments and innovations in biotech.",
      },
      {
        image: automotive,
        title: "Supply Chain Optimization",
        description:
          "AI-powered solutions that ensure efficient production and distribution of life science products.",
      },
    ],
  },
  {
    header: {
      title: "Manufacturing",
      subtitle:
        "AI Driven Manufacturing: Optimizing Efficiency and Quality Control",
      description:
        "AI is transforming manufacturing by automating production processes, improving quality control, and enhancing supply chain management. DeepMagic’s AI-powered solutions help manufacturers stay competitive in a fast-paced industry.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Improving production efficiency by 25% for a global manufacturer with AI-based predictive maintenance.",
    },
    sections: [
      {
        image: research,
        title: "Smart Factories",
        description:
          "AI-based automation tools that enhance production efficiency and reduce downtime.",
      },
      {
        image: cloud,
        title: "Predictive Maintenance",
        description:
          "AI systems that monitor machinery health and predict maintenance needs, reducing operational costs.",
      },
      {
        image: personalise,
        title: "Quality Control",
        description:
          "AI-powered tools that ensure products meet the highest quality standards, reducing defects and waste.",
      },
      {
        image: supply,
        title: "Supply Chain Optimization",
        description:
          "AI models that streamline logistics and inventory management for faster, more efficient production.",
      },
    ],
  },
  {
    header: {
      title: "Oil & Gas",
      subtitle:
        "AI in Oil & Gas: Enhancing Exploration, Production, and Sustainability",
      description:
        "DeepMagic brings AI innovations to the oil and gas sector, improving exploration, optimizing production, and ensuring environmental sustainability in this high-stakes industry.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing operational costs by 15% for an oil and gas company with AI-powered exploration tools.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Exploration Optimization",
        description:
          "AI models that analyze geological data to identify promising oil and gas reserves, reducing exploration costs.",
      },
      {
        image: personalise,
        title: "Production Efficiency",
        description:
          "AI-driven tools that optimize drilling and extraction processes, improving output and minimizing waste.",
      },
      {
        image: cloud,
        title: "Predictive Maintenance",
        description:
          "AI systems that monitor equipment and predict maintenance needs, reducing downtime and improving safety.",
      },
      {
        image: automotive,
        title: "Sustainability Solutions",
        description:
          "AI-powered tools that help oil and gas companies reduce environmental impact through efficient resource management.",
      },
    ],
  },
  {
    header: {
      title: "Retail",
      subtitle:
        "AI Solutions for Retail: Enhancing Customer Experience and Operational Efficiency",
      description:
        "DeepMagic helps retailers stay competitive by leveraging AI to personalize customer experiences, optimize supply chains, and streamline operations.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing online sales by 30% for a retail brand through AI-driven customer personalization.",
    },
    sections: [
      {
        image: research,
        title: "Personalized Shopping Experiences",
        description:
          "AI systems that analyze customer behavior and preferences to provide tailored product recommendations.",
      },
      {
        image: cloud,
        title: "Inventory Management",
        description:
          "AI-powered tools that optimize stock levels, reducing overstock and stockouts.",
      },
      {
        image: personalise,
        title: "Supply Chain Optimization",
        description:
          "AI-driven logistics tools that enhance delivery speed and efficiency.",
      },
      {
        image: automotive,
        title: "AI-Powered Marketing",
        description:
          "AI models that create targeted marketing campaigns based on customer insights.",
      },
    ],
  },
  {
    header: {
      title: "Transportation & Logistics",
      subtitle:
        "AI Solutions for Transportation & Logistics: Driving Efficiency and Reliability",
      description:
        "DeepMagic optimizes transportation and logistics operations with AI-powered tools that streamline route planning, improve fleet management, and reduce operational costs.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Reducing delivery times by 20% for a logistics company through AI-based route optimization.",
    },
    hasBackground: true,
    sections: [
      {
        image: core,
        title: "Fleet Management",
        description:
          "AI tools that monitor vehicle health, optimize routes, and improve fuel efficiency.",
      },
      {
        image: route,
        title: "Route Optimization",
        description:
          "AI-driven systems that reduce delivery times and costs by analyzing traffic patterns and weather data.",
      },
      {
        image: settings,
        title: "Predictive Maintenance",
        description:
          "AI models that ensure vehicles are maintained proactively, reducing downtime.",
      },
      {
        image: supply,
        title: "Supply Chain Management",
        description:
          "AI-powered logistics solutions that improve overall supply chain efficiency, from production to delivery.",
      },
    ],
  },
  {
    header: {
      title: "Travel & Hospitality",
      subtitle: "AI Driven Innovation in Travel & Hospitality",
      description:
        "DeepMagic empowers the travel and hospitality industry with AI solutions that personalize guest experiences, streamline operations, and optimize revenue management.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Increasing guest satisfaction by 25% for a major hotel chain with AI-powered personalized services.",
    },
    sections: [
      {
        image: personalise,
        title: "Personalized Customer Experience",
        description:
          "AI systems that provide tailored travel recommendations and hospitality services based on customer preferences.",
      },
      {
        image: economy,
        title: "Revenue Management",
        description:
          "AI-driven tools that optimize pricing strategies, improving occupancy rates and profitability.",
      },
      {
        image: personalise,
        title: "Operational Efficiency",
        description:
          "AI models that streamline hotel management, booking systems, and customer service.",
      },
      {
        image: chatbot,
        title: "AI Chatbots",
        description:
          "AI-powered virtual assistants that handle customer queries, providing instant support.",
      },
    ],
  },
  {
    header: {
      title: "Utilities",
      subtitle:
        "AI Solutions for Utilities: Optimizing Energy Management and Sustainability",
      description:
        "DeepMagic helps utility companies improve energy management, optimize grid performance, and enhance sustainability efforts through AI-driven insights and automation.",
      sections_header: "Key Offerings",
      case_study: "Case Study",
      case_study_content:
        "Improving grid efficiency by 15% for a utility provider through AI-powered energy management tools.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "Smart Grid Management",
        description:
          "AI tools that optimize energy distribution, reducing waste and improving grid reliability.",
      },
      {
        image: settings,
        title: "Predictive Maintenance",
        description:
          "AI systems that monitor utility infrastructure and predict equipment failures, ensuring continuous service.",
      },
      {
        image: route,
        title: "Energy Efficiency",
        description:
          "AI-powered tools that help customers manage their energy usage more effectively, promoting sustainability.",
      },
      {
        image: sustain,
        title: "Sustainability Solutions",
        description:
          "AI models that help utilities adopt renewable energy sources and reduce carbon emissions.",
      },
    ],
  },
];

export default data;
