import core from "assets/images/home/core.svg";
import home from "assets/images/careers/home.svg";
import teamWork from "assets/images/careers/teamwork.svg";
import customer from "assets/images/careers/customer.svg";
import impact from "assets/images/careers/impact.svg";
import healthcare from "assets/images/home/healthcare.svg";
import lotus from "assets/images/home/deepGreen.svg";
import chatbot from "assets/images/industries/chatbot.svg";
import globe from "assets/images/careers/globle.svg";
import flexible from "assets/images/careers/flexible.svg";

export const coreValuesData = [
  {
    header: {
      title: "Our Core Values",
      description:
        "At DeepMagic, our values define who we are and how we work. These guiding principles shape our culture, drive our innovation, and inspire our commitment to making a positive impact.",
      sections_header: "Key Sustainability Areas",
    },

    sections: [
      {
        image: core,
        title: "Innovation at Our Core",
        description:
          "We push the boundaries of technology and thinking, always looking for new ways to solve complex problems and create lasting change.",
      },
      {
        image: home,
        title: "Integrity & Accountability",
        description:
          "We hold ourselves to the highest ethical standards, ensuring transparency, responsibility, and fairness in all that we do.",
      },
      {
        image: teamWork,
        title: "Collaboration & Teamwork",
        description:
          "We believe that great ideas come from collaboration. We foster an open, inclusive environment where everyone’s voice is heard, and teamwork leads to success.",
      },
      {
        image: customer,
        title: "Customer-Centric Focus",
        description:
          "Our customers are at the heart of everything we do. We are committed to delivering solutions that meet their needs, exceed their expectations, and drive meaningful results.",
      },
      {
        image: impact,
        title: "Sustainability & Impact",
        description:
          "We are dedicated to making a positive impact on the world. From driving sustainable practices to fostering human- centric AI, we’re committed to building a better future for all.",
      },
    ],
  },
];

export const deepMagicLife = [
  {
    header: {
      title: "Work Hard, Innovate, and Thrive",
      description:
        "At DeepMagic, we believe in creating a workplace that inspires creativity, fosters collaboration, and nurtures well-being. Our culture is built on trust, mutual respect, and a shared commitment to excellence. We encourage our team members to explore new ideas, take risks, and challenge the status quo.",
      sub_description:
        "We also understand the importance of work-life balance. That’s why we offer flexible work arrangements, wellness programs, and a supportive environment that helps you thrive both inside and outside the office",
      sections_header: "What Life at DeepMagic Looks",
    },

    sections: [
      {
        image: chatbot,
        title: "Collaborative Workspaces",
        description: "Open, dynamic environments where innovation thrives.",
      },
      {
        image: globe,
        title: "Global Opportunities",
        description: "Work with colleagues and clients across the world.",
      },
      {
        image: lotus,
        title: "Inclusive Culture",
        description:
          "Be part of a diverse team that celebrates individual perspectives.",
      },
      {
        image: healthcare,
        title: "Wellness Programs",
        description:
          "Health and wellness initiatives designed to keep you at your best.",
      },
      {
        image: flexible,
        title: "Flexible Work Options",
        description:
          "Remote and hybrid work models to support work-life integration.",
      },
    ],
  },
];
