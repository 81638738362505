import React from "react";
import { Button, Card, Grid, Header, Image } from "semantic-ui-react";
import banner from "../../assets/images/about/banner.webp";
import style from "./scss/aboutus.module.scss";
import brain from "assets/images/home/ai.svg";
import aiStrategy from "assets/images/home/core.svg";
import emerging from "assets/images/research/culture.svg";
import research from "assets/images/home/research.svg";
import placeholder from "assets/images/about/placeholder.svg";
import phone from "assets/images/about/phone.svg";
import whatsapp from "assets/images/about/whatsapp.svg";
import mail from "assets/images/about/mail.svg";
import goverance from "assets/images/about/goverance.webp";
import diversity from "assets/images/about/diversity.webp";
import culture from "assets/images/about/culture.webp";
import newspaper from "assets/images/about/newspapers.webp";
import partnership from "assets/images/about/partnership.webp";
import publicPolicy from "assets/images/about/publicPolicy.webp";
import sponsorship from "assets/images/about/sponsorship.webp";
import citizenship from "assets/images/about/citizenship.webp";
import talent from "assets/images/about/talent.webp";
import { awardAndBoardData, clientAndContact } from "./aboutUsData";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Aboutus = () => {
  const leadershipCard = [
    {
      id: 1,
      image: newspaper,
      header: "News & Press Releases",
      description: "Stay Updated with the Latest News from DeepMagic",
      buttonText: "Know More",
    },
    {
      id: 2,
      image: partnership,
      header: "Partnerships",
      description: "Collaborating for Innovation and Impact",
      buttonText: "Know More",
    },
    {
      id: 3,
      image: publicPolicy,
      header: "Public Policy",
      description:
        "Shaping Public Policy for a Sustainable and Inclusive Future.",
      buttonText: "Know More",
    },
    {
      id: 4,
      image: sponsorship,
      header: "Sponsorships",
      description: "Supporting Innovation, Education, and Sustainability",
      buttonText: "Know More",
    },
    {
      id: 5,
      image: citizenship,
      header: "Sustainability and Corporate Citizenship",
      description: "Building a Sustainable and Responsible Future",
      buttonText: "Know More",
    },
    {
      id: 6,
      image: talent,
      header: "Talent Worldwide",
      description: "JGlobal Talent, Local Impact",
      buttonText: "Know More",
    },
  ];

  const aboutUsCoreData = [
    {
      id: 1,
      name: "Innovation",
      image: aiStrategy,
    },
    {
      id: 2,
      name: "Sustainability",
      image: emerging,
    },
    {
      id: 3,
      name: "Expertise",
      image: research,
    },
    {
      id: 4,
      name: "Integrity",
      image: brain,
    },
  ];

  const contactUsCard = [
    {
      id: 1,
      image: goverance,
      header: "Corporate Governance",
      description: "Strong Governance for Sustainable Growth",
      buttonText: "Know More",
    },
    {
      id: 2,
      image: culture,
      header: "Culture & Values",
      description: "Strong Governance for Sustainable Growth",
      buttonText: "Know More",
    },
    {
      id: 3,
      image: diversity,
      header: "Diversity & Inclusion",
      description: "Strong Governance for Sustainable Growth",
      buttonText: "Know More",
    },
  ];

  const leadersProfile = [
    {
      id: 1,
      image: placeholder,
      name: "Chairperson",
      position: "CEO",
      about:
        "A  visionary leader with expertise in AI and digital transformation. ",
    },
    {
      id: 2,
      image: placeholder,
      name: "Chairperson",
      position: "CTO",
      about:
        "An innovator in AI technologies, leading DeepMagic’s product development.",
    },
  ];
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <div className={style.aboutusContainer}>
      <Grid
        className={style.service_wrapper}
        verticalAlign="middle"
        stretched
        padded={isResponsive}
      >
        <Grid.Column computer={12} mobile={16} className={style.leftBanner}>
          <h1 className={style.industry_header}>About DeepMagic</h1>
          <p className={style.description}>
            DeepMagic is a global leader in AI services, dedicated to helping
            organizations innovate and grow in an AI-driven world. With decades
            of experience, we blend deep research, technical expertise, and
            future-focused strategies to provide industry-specific AI solutions.
            Our mission is to enable businesses to unlock their full potential
            through intelligent automation, digital transformation, and
            sustainable growth.
          </p>
        </Grid.Column>
        {!isResponsive && (
          <>
            <Grid.Column computer={4} className={style.rightBanner}>
              <Image src={banner} />
            </Grid.Column>
          </>
        )}
      </Grid>
      <div className={style.aboutUsCoreValues}>
        <h1>Core Values</h1>
        <Grid columns={isResponsive ? 2 : 4}>
          <Grid.Row>
            {aboutUsCoreData.map((ele) => (
              <React.Fragment key={ele.id}>
                <Grid.Column textAlign="center">
                  <Image src={ele.image} />
                  <Header>{ele.name}</Header>
                  <p>{ele.content}</p>
                </Grid.Column>
              </React.Fragment>
            ))}
          </Grid.Row>
        </Grid>
      </div>

      <Grid padded={isResponsive} columns={isResponsive ? 1 : 2}>
        <Grid.Column className={style.aboutUsMissionVision}>
          <h1>Our Mission</h1>
          <p>
            To lead AI-powered transformation across industries, driving
            sustainable growth, enhancing resilience, and creating future-ready
            businesses.
          </p>
        </Grid.Column>
        <Grid.Column className={style.aboutUsMissionVision}>
          <h1>Our Vision</h1>
          <p>
            To be at the forefront of AI innovation, shaping a smarter, more
            connected, and sustainable world.
          </p>
        </Grid.Column>
      </Grid>

      {awardAndBoardData.map((item, index) => (
        <div key={index} className={style.wrappper}>
          <h1>{item.header.title}</h1>
          <h3>{item.header.sub_title}</h3>
          <p>{item.header.description}</p>
          <p>{item.header.sub_description}</p>
          {item.sections && (
            <>
              <h3>{item.header.sections_header}</h3>
              <Grid padded centered stretched columns={isResponsive ? 1 : 2}>
                {item.sections.map((section, sectionIndex) => (
                  <Grid.Column key={sectionIndex} textAlign="center">
                    <div>
                      <Image src={section.image} />
                      <h4>{section.title}</h4>
                      <p>{section.description}</p>
                      {item.sections[0].buttonText && (
                        <>
                          <Button className="silverBgbutton">Explore</Button>
                        </>
                      )}
                    </div>
                  </Grid.Column>
                ))}
              </Grid>
            </>
          )}
          {item.header.memberList && (
            <>
              <Grid padded columns={isResponsive ? 1 : 2}>
                {item.header.memberList.map((member) => (
                  <>
                    <Grid.Column>
                      <div className="clr20" />
                      <Image src={placeholder} />
                      <Header as="h2">{member.name}</Header>
                      <p>{member.role}</p>
                      <p>{member.description}</p>
                    </Grid.Column>
                  </>
                ))}
              </Grid>
            </>
          )}
        </div>
      ))}

      {clientAndContact.map((item, index) => (
        <div key={index} className={style.wrappper}>
          <h1>{item.header.title}</h1>
          <h3>{item.header.sub_title}</h3>
          <p>{item.header.description}</p>
          <p>{item.header.sub_description}</p>
          {item.sections && (
            <>
              <h3>{item.header.sections_header}</h3>
              <Grid centered stretched columns={isResponsive ? 1 : 3}>
                {item.sections.map((section, sectionIndex) => (
                  <Grid.Column key={sectionIndex} textAlign="center">
                    <div>
                      <Image src={section.image} />
                      <h4>{section.title}</h4>
                      <p>{section.description}</p>
                    </div>
                  </Grid.Column>
                ))}
              </Grid>
            </>
          )}
          {item.sections[0]?.buttonText && (
            <>
              <Button className="silverBgbutton">
                {item.sections[0]?.buttonText}
              </Button>
            </>
          )}
        </div>
      ))}

      <Grid columns={isResponsive ? 1 : 3} className={style.contactUs}>
        <Grid.Column textAlign="center">
          <h3>Contact Us for Demo</h3>
          <div className="clr20" />
          <div className={style.contactUsImg}>
            <Image src={phone} />
            <Image src={whatsapp} />
            <Image src={mail} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <h3>Connect with Us</h3>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <h3>Our Location</h3>
          <Button className={style.contactUsMapBtn}>View in Map</Button>
        </Grid.Column>
      </Grid>
      <div className="clr20" />
      <Grid
        columns={isResponsive ? 1 : 3}
        container
        stretched
        centered
        className={style.cardDetails}
      >
        {contactUsCard.map((card) => (
          <Grid.Column key={card.id}>
            <div className={style.cardContent}>
              <Image src={card.image} />
              <Card.Content textAlign="center" className={style.content}>
                <Card.Header>{card.header}</Card.Header>
                <Card.Description className={style.desc}>
                  {card.description}
                </Card.Description>
              </Card.Content>
              <Button className={`silverBgbutton ${style.buttonTxt}`}>
                {card.buttonText}
              </Button>
            </div>
          </Grid.Column>
        ))}
      </Grid>

      <div className={style.wrappper}>
        <h1>Leadership Team</h1>
        <h2>Meet the Leaders Behind Our Vision</h2>
        <p>
          DeepMagic’s leadership team is composed of industry experts and
          innovators who are passionate about shaping the future of technology
          and AI. Their experience and vision are instrumental in driving our
          company’s success and ensuring we remain at the forefront of
          innovation.
        </p>
        <p>
          Our leadership team is committed to creating a collaborative,
          forward-thinking environment where innovation thrives and our clients
          achieve exceptional results.
        </p>
      </div>

      <Grid container columns={isResponsive ? 1 : 2}>
        {leadersProfile.map((member) => (
          <>
            <Grid.Column>
              <div className="clr20" />
              <Image src={placeholder} />
              <Header as="h2">{member.name}</Header>
              <p>{member.position}</p>
              <p>{member.about}</p>
            </Grid.Column>
          </>
        ))}
      </Grid>
      <div className="clr20" />
      <div className="clr20" />
      <Grid
        columns={isResponsive ? 1 : 3}
        container
        stretched
        centered
        className={style.cardDetails}
      >
        {leadershipCard.map((card) => (
          <Grid.Column key={card.id}>
            <div className={style.cardContent}>
              <Image src={card.image} />
              <Card.Content textAlign="center" className={style.content}>
                <Header textAlign="center">{card.header}</Header>
                <Card.Description className={style.desc}>
                  {card.description}
                </Card.Description>
              </Card.Content>
              <Button className={`silverBgbutton ${style.buttonTxt}`}>
                {card.buttonText}
              </Button>
            </div>
          </Grid.Column>
        ))}
      </Grid>
      <div className="clr20" />
      <div className="clr20" />
    </div>
  );
};
export default Aboutus;
