import PageTemplate from "globals/components/PageTemplate";
import React from "react";
import style from "./scss/research.module.scss";
import { Grid, GridColumn, Image } from "semantic-ui-react";
import researchRightBanner from "assets/images/research/research_right_banner.webp";
import data from "./researchData";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Research = () => {
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <>
      <div className={style.industryContainer}>
        <Grid
          className={style.industries_wrapper}
          verticalAlign="middle"
          stretched
        >
          <GridColumn computer={12} mobile={16} className={style.leftBanner}>
            <h1 className={style.industry_header}>
              AI Research for Business Innovation and Sustainability
            </h1>
            <p className={style.description}>
              At DeepMagic, we lead pioneering research that explores how AI and
              emerging technologies are shaping industries, economies, and
              societies. Our in-depth research provides insights into AI’s
              transformative power and guides businesses on how to embrace
              innovation, sustainability, and future-readiness.
            </p>
          </GridColumn>
          {!isResponsive && (
            <>
              <GridColumn computer={4} className={style.rightBanner}>
                <Image src={researchRightBanner} />
              </GridColumn>
            </>
          )}
        </Grid>
        <PageTemplate columnCount={4} data={data} />
      </div>
    </>
  );
};

export default Research;
