import React from "react";
import { Card, Container, Grid, Header, Image } from "semantic-ui-react";
import style from "./scss/insights.module.scss";
import aiStrategy from "assets/images/home/core.svg";
import emerging from "assets/images/research/culture.svg";
import personalise from "assets/images/industries/personalise.svg";
import rightBanner from "assets/images/insights/banner.webp";
import deepServices from "assets/images/insights/article1.webp";
import deepResearch from "assets/images/insights/article2.webp";
import deepConsultation from "assets/images/insights/article3.webp";
import brain from "assets/images/home/ai.svg";
import arrow from "assets/images/insights/arrow.svg";
import Slider from "react-slick";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Insights = () => {
  const insightCardData = [
    {
      id: 1,
      name: "AI Strategy & Consultation",
      content:
        "Discover how AI is revolutionizing business strategy and transforming industries across the globe.",
      image: aiStrategy,
    },
    {
      id: 2,
      name: "Emerging Technologies & Trends",
      content:
        "Stay ahead of the curve with insights into the latest advancements in AI, machine learning, and automation.",
      image: emerging,
    },
    {
      id: 3,
      name: "Ethical AI & Governance",
      content:
        "Understand the challenges and solutions for building ethical, transparent, and secure AI systems.",
      image: personalise,
    },
    {
      id: 4,
      name: "Ethical AI & Governance",
      content:
        "Understand the challenges and solutions for building ethical, transparent, and secure AI systems.",
      image: brain,
    },
  ];
  const insightArticleData = [
    {
      id: 1,
      name: "AI DeepServices: Powering the Transformation of Industries for the Next Decade",
      content:
        "In the past decade, artificial intelligence (AI) has evolved from a futuristic concept to a transformative force that is reshaping industries across the globe",
      image: deepServices,
    },
    {
      id: 2,
      name: "AI DeepResearch: Shaping the Future of Industries for the Next Decade",
      content:
        "Artificial intelligence (AI) is no longer confined to the realm of science fiction; it has become the catalyst for the next great industrial transformation.",
      image: deepResearch,
    },
    {
      id: 3,
      name: "AI DeepConsultation: Navigating the Next Decade of Industry Evolution",
      content:
        "The dawn of artificial intelligence (AI) marks the beginning of a transformative era, where industries across the globe are undergoing a profound shift.",
      image: deepConsultation,
    },
  ];
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={style.nextarrow} onClick={onClick}>
        <Image size="tiny" src={arrow} alt="Next" />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={style.prevarrow} onClick={onClick}>
        <Image size="tiny" src={arrow} alt="Next" />
      </div>
    );
  };
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <div className={style.industryContainer}>
      <Grid
        className={style.industries_wrapper}
        verticalAlign="middle"
        stretched
      >
        <Grid.Column computer={12} mobile={16} className={style.leftBanner}>
          <h1 className={style.industry_header}>
            Insights into the Future of AI-Driven Transformation
          </h1>
          <p className={style.description}>
            Explore groundbreaking research, in-depth articles, and thought
            leadership that reveal how AI is revolutionizing industries, driving
            innovation, and shaping the future of business.
          </p>
        </Grid.Column>
        {!isResponsive && (
          <>
            <Grid.Column computer={4} className={style.rightBanner}>
              <Image src={rightBanner} />
            </Grid.Column>
          </>
        )}
      </Grid>
      <div className="clr20" />
      <Header textAlign="center" as="h2">
        Deep Insights into the World of AI
      </Header>
      <Container>
        <p>
          At DeepMagic, we go beyond AI implementation. We provide cutting-edge
          insights into how AI is reshaping industries, driving innovation, and
          transforming the way businesses operate. Our AI research, articles,
          and thought leadership give you a front-row seat to the next wave of
          technological evolution. Whether you’re looking for deep dives into AI
          strategy, the ethical implications of AI, or the future of automation
          and sustainability, our insights page is your gateway to understanding
          the AI-driven future.
        </p>
      </Container>
      <div className="clr20" />
      <div className={style.coverSection}>
        <Grid padded columns={isResponsive ? 1 : 4}>
          <Grid.Row>
            {insightCardData.map((ele) => (
              <React.Fragment key={ele.id}>
                <Grid.Column textAlign="center">
                  <Image src={ele.image} />
                  <Header>{ele.name}</Header>
                  <p>{ele.content}</p>
                </Grid.Column>
              </React.Fragment>
            ))}
          </Grid.Row>
        </Grid>
      </div>
      <Header as="h2">Insightful Reads</Header>
      <Header as="h3">Articles & Insights Hub</Header>
      <Container className={style.articleContainer}>
        <Slider
          slidesToScroll={1}
          autoplay={isResponsive}
          autoplaySpeed={2000}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          slidesToShow={isResponsive ? 1 : 2.1}
        >
          {insightArticleData.map((ele) => (
            <>
              <div className="clr20" />
              <Card className={style.articleCard} fluid raised>
                <Card.Content padded>
                  <Header>Article {ele.id}</Header>
                  <Image src={ele.image} />
                  <div className="clr20" />
                  <Card.Header textAlign="center" as="h5">
                    {ele.name}
                  </Card.Header>
                  <Card.Description>{ele.content}</Card.Description>
                </Card.Content>
              </Card>
              <div className="clr20" />
            </>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Insights;
