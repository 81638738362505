import lotus from "assets/images/home/deepGreen.svg";
import home from "assets/images/home/city.svg";

export const awardAndBoardData = [
  {
    header: {
      title: "Awards & Accolades",
      sub_title: "Celebrating Excellence Through Recognition",
      description:
        "At DeepMagic, we strive for excellence in everything we do. Our innovation, dedication, and impact have been recognized globally through various industry awards and accolades. These honors reflect our commitment to driving innovation, delivering exceptional results for our clients, and contributing to a better future.",
      sub_description:
        "We are proud to have received numerous awards that highlight our achievements in AI, sustainability, customer service, and more. From industry- leading organizations to client-driven testimonials, these recognitions inspire us to continue delivering value and pushing boundaries.",
    },
    sections: [
      {
        image: lotus,
        title: "2023 AI Innovation Award",
        description:
          "Recognizing our cutting-edge AI solutions that drive business transformation.",
      },
      {
        image: home,
        title: "Sustainability Excellence Award 2022",
        description:
          "For our impactful sustainability initiatives in reducing carbon emissions across industries.",
      },
    ],
  },

  {
    header: {
      title: "Be Deeply Magic",
      sub_title: "Embrace the Magic of Innovation and Growth",
      description:
        "At DeepMagic, we empower individuals and teams to unlock their potential through creativity, innovation, and collaboration. To  Be Deeply Magic is to embrace change, challenge the status quo, and commit to continuous learning and growth. It’s about being part of something bigger – transforming industries, solving complex challenges, and building a better future through technology.",
      sub_description:
        "Join a community where your ideas matter, where you can make an impact, and where you can grow your skills alongside some of the brightest minds in the industry. We believe that everyone at DeepMagic has the power to create something magical, and we’re here to support you every step of the way.",
    },
    sections: [
      {
        buttonText: "Explore",
      },
    ],
  },
  {
    header: {
      title: "Board of Directors",
      sub_title: "Guiding Our Vision for the Future",
      description:
        "Our Board of Directors is comprised of distinguished leaders from diverse industries who bring a wealth of experience, strategic insight, and a shared commitment to our mission. They play a crucial role in shaping our long-term strategy, ensuring strong corporate governance, and driving innovation that aligns with our values.",
      sub_description:
        "Meet the leaders who guide DeepMagic’s strategic vision and growth. Their leadership ensures that we remain at the forefront of innovation, maintain our commitment to sustainability, and continue delivering unparalleled value to our clients and stakeholders.",
      memberList: [
        {
          id: 1,
          name: "Chairperson",
          role: "Chairperson",
          description:
            "A visionary leader with over 30 years of experience in AI and technology.",
        },
        {
          id: 1,
          name: "Director",
          role: "Director",
          description:
            "Brings expertise in corporate governance and sustainability initiatives.",
        },
      ],
    },
  },
];

export const clientAndContact = [
  {
    header: {
      title: "Client Briefing Program",
      sub_title: "Engage with Our Experts for Customized Insights",
      description:
        "DeepMagic’s Client Briefing Program offers a unique opportunity for our clients and partners to engage with our subject matter experts. Through tailored briefings, we provide insights into the latest trends, AI innovations, and strategies that can drive growth and transformation in your business.",
      sub_description:
        "Our Client Briefing Program is designed to provide you with in-depth knowledge and actionable insights specific to your industry. From emerging technologies to AI-driven strategies, our team of experts is here to help you navigate the complexities of the modern business landscape.",
    },
    sections: [
      {
        buttonText: "Schedule a Session",
      },
    ],
  },

  {
    header: {
      title: "Contact Us",
      sub_title: "Get in Touch with DeepMagic",
      description:
        "Whether you have a question, need more information about our services, or want to explore partnership opportunities, we’re here to help. Reach out to us and connect with our team – we’re ready to assist you.",
      sub_description:
        "For inquiries about our services, solutions, or career opportunities, feel free to contact us using the information below.",
    },
    sections: [],
  },
];
