/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Grid, Image, Button, Header } from "semantic-ui-react";
import style from "./pageTemplate.module.scss";
import robot1 from "assets/images/robot1.webp";
import robot2 from "assets/images/robot2.webp";
import robot3 from "assets/images/robot3.webp";
import robot4 from "assets/images/robot4.webp";
import robot5 from "assets/images/robot5.webp";
import robot6 from "assets/images/robot6.webp";
import robot7 from "assets/images/robot7.webp";
import robot8 from "assets/images/robot8.webp";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const handleNavigate = (value) => {
  window.location.href = value;
};

const robotList = [
  robot1,
  robot2,
  robot3,
  robot4,
  robot5,
  robot6,
  robot7,
  robot8,
];

const PageSection = ({ section, pageData, columnCount }) => (
  <Grid.Column
    computer={columnCount === 4 ? 4 : 5}
    mobile={16}
    textAlign="center"
  >
    <div className={style.column_wrapper}>
      <Image src={section.image} />
      <h4>{section.title}</h4>
      <p>{section.description}</p>
      {section.buttonEnable && section.buttonText && (
        <div className={style.btn_wrapper}>
          <Button
            onClick={
              section.routeValue !== undefined
                ? () => handleNavigate(section.routeValue)
                : () => {}
            }
            className={
              !pageData.hasBackground ? "silverBgbutton" : "whiteBgbutton"
            }
          >
            {section.buttonText}
          </Button>
        </div>
      )}
    </div>
  </Grid.Column>
);

const generateRandomBgClass = () => {
  const backgroundClassList = [
    style.pageWrapperBg1,
    style.pageWrapperBg2,
    style.pageWrapperBg3,
    style.pageWrapperBg4,
    style.pageWrapperBg5,
    style.pageWrapperBg6,
    style.pageWrapperBg7,
    style.pageWrapperBg8,
    style.pageWrapperBg9,
    style.pageWrapperBg10,
  ];
  const randomIndex = Math.floor(Math.random() * backgroundClassList.length);
  return backgroundClassList[randomIndex];
};

const Page = ({ pageData, columnCount }) => {
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  const randomBgClass =
    pageData.defaultBg !== undefined
      ? style.pageWrapperBg7
      : generateRandomBgClass();
  const pageWrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (pageWrapperRef.current) {
      observer.observe(pageWrapperRef.current);
    }

    return () => {
      if (pageWrapperRef.current) {
        observer.unobserve(pageWrapperRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={pageWrapperRef}
      className={`${style.page_wrapper} ${
        pageData?.hasBackground ? style.oddBackground : ""
      } ${pageData.hasBackground && randomBgClass} ${
        isVisible ? style.visible : ""
      }`}
    >
      {pageData.hasBackground && (
        <Image
          className={style.robot1}
          src={robotList[Math.floor(Math.random() * 8)]}
        />
      )}
      <h1>{pageData.header.title}</h1>
      <h2>{pageData.header.subtitle || ""}</h2>
      <p>{pageData.header.description}</p>
      {pageData.sections !== undefined ? (
        <>
          <h3>{pageData.header.sections_header}</h3>
          <Grid relaxed={isResponsive} centered stretched>
            {pageData.sections?.map((section, index) => (
              <PageSection
                key={index}
                section={section}
                pageData={pageData}
                columnCount={columnCount}
              />
            ))}
          </Grid>
        </>
      ) : (
        <>
          <div>
            {pageData.header.buttonEnable && pageData.header.buttonText && (
              <div className={style.btn_wrapper}>
                <Button
                  onClick={
                    pageData.header.routeValue !== undefined
                      ? () => handleNavigate(pageData.header.routeValue)
                      : () => {}
                  }
                  className={
                    pageData.hasBackground ? "silverBgbutton" : "whiteBgbutton"
                  }
                >
                  {pageData.header.buttonText}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      {pageData.header.case_study && (
        <>
          <Header>{pageData.header.case_study}</Header>
          <p className={style.caseStudy}>
            {pageData.header.case_study_content}
          </p>
        </>
      )}
      {pageData.hasBackground && (
        <Image
          className={style.robot2}
          src={robotList[Math.floor(Math.random() * 8)]}
        />
      )}
    </div>
  );
};

const PageTemplate = (props) => {
  const { data, columnCount } = props;
  return (
    <div>
      {data.map((pageData, index) => (
        <Page
          key={index}
          pageData={pageData}
          index={index}
          columnCount={columnCount}
        />
      ))}
    </div>
  );
};

export default PageTemplate;
