import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "globals/components/header/Header";
import "assets/scss/main.scss";
import Footer from "globals/components/Footer/Footer";
import HomeScreen from "screens/home/HomeScreen";
import DefaultLoader from "globals/components/DefaultLoader";
import Industries from "screens/industries/Industries";
import Research from "screens/research/Research";
import Services from "screens/services/Services";
import Insights from "screens/Insights/Insights";
import Careers from "screens/careers/Careers";
import Aboutus from "screens/aboutus/Aboutus";

class App extends React.Component {
  render = () => {
    return (
      <>
        <Header />
        <Suspense fallback={<DefaultLoader />}>
          <Switch>
            <Route exact path="/home" component={HomeScreen} />
            <Route exact path="/aboutUs" component={Aboutus} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/insights" component={Insights} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/industries" component={Industries} />
            <Route exact path="/research" component={Research} />
            <Route exact path="/">
              <HomeScreen />
            </Route>
          </Switch>
          <Footer />
        </Suspense>
      </>
    );
  };
}

export default App;
