import React from "react";
import {
  Button,
  Card,
  Grid,
  GridColumn,
  // Header,
  Image,
} from "semantic-ui-react";
import style from "./scss/careers.module.scss";
import global from "assets/images/careers/global.webp";
import careersRightBanner from "assets/images/careers/careers_right_banner.webp";
import innovative from "assets/images/careers/innovative.webp";
import continuous_learning from "assets/images/careers/continuous_learning.webp";
import culture from "assets/images/careers/culture.webp";
import benefits from "assets/images/careers/benefits.webp";
import ai_ml from "assets/images/careers/ai_ml.webp";
import data_scientists from "assets/images/careers/data_scientists.webp";
import consultants from "assets/images/careers/consultants.webp";
import designers from "assets/images/careers/designers.webp";
import sales from "assets/images/careers/sales.webp";
import internships from "assets/images/careers/internships.webp";
import insights from "assets/images/industries/insights.svg";
import application from "assets/images/careers/application.svg";
import chatbot from "assets/images/industries/chatbot.svg";
import customerCentric from "assets/images/service/customerCentric.svg";
import { coreValuesData, deepMagicLife } from "./careersData";
import PageTemplate from "globals/components/PageTemplate";
import { mediaBreakpoint } from "globals/utils/globalVariables";
// import FeedbackCarousel from "./FeedbackCarousel";

const Careers = () => {
  const careersCard = [
    {
      id: 1,
      image: innovative,
      header: "Innovative Work Environment",
      description:
        "At DeepMagic, you’ll work with cutting-edge AI technologies and collaborate with some of the brightest minds in the industry. We foster a culture of curiosity and creativity, where you’re encouraged to push boundaries, explore new ideas, and lead with innovation.",
    },
    {
      id: 2,
      image: global,
      header: "Global Impact",
      description:
        "Join a team that is transforming industries on a global scale. From healthcare and finance to manufacturing and retail, DeepMagic’s AI solutions are driving real-world change. Be part of a company that’s making a difference, helping businesses and society tackle the challenges of tomorrow.",
    },
    {
      id: 3,
      image: continuous_learning,
      header: "Continuous Learning & Development",
      description:
        "We believe in investing in our people. At DeepMagic, you’ll have access to cutting-edge training, certifications, and development programs to help you advance your skills and grow your career. Whether you’re deepening your AI expertise or expanding your leadership abilities, we provide the tools and support to help you reach your full potential.",
    },
    {
      id: 4,
      image: culture,
      header: "Inclusive & Diverse Culture",
      description:
        "We thrive on diversity – of ideas, perspectives, and experiences. At DeepMagic, we are committed to building an inclusive workplace where everyone feels valued and respected. Our team comes from all over the world, and we celebrate the unique contributions of every individual.",
    },
    {
      id: 5,
      image: benefits,
      header: "Competitive Compensation & Benefits",
      description:
        "We offer market-leading compensation packages, including performance-based incentives, comprehensive health benefits, flexible work arrangements, and wellness programs. Our benefits are designed to support you both professionally and personally.",
    },
  ];

  const careerAreas = [
    {
      id: 1,
      image: ai_ml,
      header: "AI & Machine Learning Engineers",
      description:
        "Lead the design, development, and deployment of AI models that transform industries. Our AI engineers are at the cutting edge of innovation, developing solutions that solve complex real- world problems.",
    },
    {
      id: 2,
      image: data_scientists,
      header: "Data Scientists & Analysts",
      description:
        "Work with vast datasets to uncover insights, drive decision-making, and build predictive models. At DeepMagic, data science is at the heart of our AI-driven solutions, offering opportunities to make a real impact.",
    },
    {
      id: 3,
      image: consultants,
      header: "Consultants & Strategists",
      description:
        "Help our clients navigate their AI transformation journey. Our consultants provide strategic guidance, implementation support, and industry- specific insights that drive AI adoption and business success.",
    },
    {
      id: 4,
      image: designers,
      header: "Product Managers & Designers",
      description:
        "Shape the future of AI products that meet the needs of industries worldwide. From ideation to execution, our product teams create solutions that make AI accessible, scalable, and impactful.",
    },
    {
      id: 5,
      image: sales,
      header: "Sales & Business Development",
      description:
        "Drive growth and expand DeepMagic’s footprint by identifying new opportunities, building client relationships, and delivering value through AI- driven innovation.",
    },
    {
      id: 6,
      image: internships,
      header: "Internships & Graduate Opportunities",
      description:
        "Jump-start your career with hands-on experience in AI and technology. Our internship and graduate programs provide a unique opportunity to work on real projects and learn from industry leaders.",
    },
  ];

  const careerJourney = [
    {
      id: 1,
      image: application,
      header: "Application",
      description:
        "Browse our open positions and apply online with your resume and cover letter.",
    },
    {
      id: 2,
      image: customerCentric,
      header: "Interview Process",
      description:
        "We conduct a series of interviews, including technical assessments, case studies, and cultural fit discussions to ensure a mutual fit.",
    },
    {
      id: 3,
      image: chatbot,
      header: "Offer & Onboarding",
      description:
        "Once selected, you’ll receive a competitive offer and join our onboarding program, where you’ll meet your new team, learn about our culture, and hit the ground running.",
    },
    {
      id: 4,
      image: insights,
      header: "Continuous Growth",
      description:
        "At DeepMagic, your growth doesn’t stop after onboarding. We provide ongoing learning and development opportunities to help you build your career.",
    },
  ];
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <>
      <div className={style.careerContainer}>
        <Grid
          className={style.careers_wrapper}
          verticalAlign="middle"
          stretched
        >
          <GridColumn computer={12} mobile={16}>
            <h1 className={style.careers_header}>
              Shape the Future with AI – Join the DeepMagic Team
            </h1>
            <p className={style.description}>
              At DeepMagic, we empower innovation, creativity, and growth. Join
              a global team where your talent drives real-world impact and AI
              solutions that shape the future of industries.
            </p>
            <div className={style.btn_container}>
              <Button className="silverBgbutton">Explore Open Positions</Button>
              <Button className="silverBgbutton">Life at DeepMagic</Button>
            </div>
          </GridColumn>
          {!isResponsive && (
            <>
              <GridColumn computer={4} className={style.careersRightBanner}>
                <Image src={careersRightBanner} />
              </GridColumn>
            </>
          )}
        </Grid>

        <div className={style.careerInfromation}>
          <h1>Why Work at DeepMagic?</h1>
          <p>
            At DeepMagic, we’re not just building technology – we’re creating
            the future. As a leader in AI-driven innovation, we empower our
            people to think big, solve complex problems, and drive
            transformative change across industries. From AI development and
            data science to consulting and business strategy, we offer
            opportunities for passionate individuals ready to shape the world
            through AI.
          </p>
          <p>
            Our team is diverse, global, and united by a shared vision – to
            harness the power of artificial intelligence to transform industries
            and create a more sustainable, efficient, and innovative world.
            Whether you’re an experienced professional or just starting your
            career, DeepMagic offers a dynamic environment where your ideas
            matter, and your skills can thrive.
          </p>
          <div className={style.btn_container}>
            <Button className="silverBgbutton">View Open Roles</Button>
            <Button className="silverBgbutton">Explore Career Paths</Button>
          </div>
        </div>

        <div className={style.careerBgContent}>
          <h1>What We Offer</h1>
          <h2>Why Choose DeepMagic?</h2>
          <h2>Key Points</h2>

          <Grid
            columns={isResponsive ? 1 : 3}
            container
            stretched
            centered
            className={style.cardDetails}
          >
            {careersCard.map((card) => (
              <Grid.Column key={card.id}>
                <Card fluid raised className={style.cardContent}>
                  <Image src={card.image} />
                  <Card.Content textAlign="center">
                    <Card.Header>{card.header}</Card.Header>
                    <Card.Description>{card.description}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
          <div className={style.white_btn_container}>
            <Button className="whiteBgbutton">About Our Culture</Button>
            <Button className="whiteBgbutton">Apply Now</Button>
          </div>
        </div>

        <div>
          <PageTemplate columnCount={5} data={coreValuesData} />
        </div>

        <div className={style.careerBgContent}>
          <h1>Find Your Path – Opportunities at Every Stage</h1>
          <p>
            DeepMagic offers a wide range of career paths for individuals
            passionate about AI, technology, and business transformation.
            Whether you’re a tech innovator, data enthusiast, or strategic
            thinker, there’s a place for you at DeepMagic.
          </p>
          <h1>Key Career Areas</h1>

          <Grid
            columns={isResponsive ? 1 : 3}
            container
            stretched
            centered
            className={style.cardDetails}
          >
            {careerAreas.map((card) => (
              <Grid.Column key={card.id}>
                <Card fluid raised className={style.cardContent}>
                  <Image src={card.image} />
                  <Card.Content textAlign="center">
                    <Card.Header>{card.header}</Card.Header>
                    <Card.Description>{card.description}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </div>
      </div>

      {deepMagicLife.map((item, index) => (
        <div key={index} className={style.deepMagicLife}>
          <h1>{item.header.title}</h1>
          <p>{item.header.description}</p>

          {item.sections && (
            <>
              <h3>{item.header.sections_header}</h3>
              <Grid centered stretched columns={isResponsive ? 1 : 3}>
                {item.sections.map((section, sectionIndex) => (
                  <Grid.Column key={sectionIndex} textAlign="center">
                    <div>
                      <Image src={section.image} />
                      <h4>{section.title}</h4>
                      <p>{section.description}</p>
                    </div>
                  </Grid.Column>
                ))}
              </Grid>
            </>
          )}
        </div>
      ))}

      {/* <div className={style.feedbackWrapper}>
        <Header as="h1">
          What Our Team Says About
          <br /> Working at DeepMagic
        </Header>
        <p>
          Don’t just take our word for it – here’s what our team members have to
          say about their experience at DeepMagic
        </p>
      </div> */}
      {/* <FeedbackCarousel /> */}
      <div className={style.careersJourney}>
        <h1>Your Journey with DeepMagic Starts Here</h1>
        <p>
          At DeepMagic, we believe in making the hiring process as transparent
          and seamless as possible. Here’s what you can expect
        </p>
        <Grid
          columns={isResponsive ? 1 : 4}
          container
          stretched
          centered
          className={style.cardDetails}
        >
          {careerJourney.map((card) => (
            <Grid.Column key={card.id}>
              <Card fluid className={style.journeyCard}>
                <div className={style.imgContainer}>
                  <Image src={card.image} />
                </div>
                <Card.Header as="h4" textAlign="center">
                  {card.header}
                </Card.Header>
                <Card.Description textAlign="center">
                  {card.description}
                </Card.Description>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      </div>

      <div className={style.btn_container}>
        <Button className="silverBgbutton">Explore Careers at DeepMagic</Button>
        <Button className="silverBgbutton">Apply Now</Button>
        <Button className="silverBgbutton">
          Learn More About Our Hiring Process
        </Button>
      </div>
    </>
  );
};

export default Careers;
