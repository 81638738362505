import research from "assets/images/home/research.svg";
import supply from "assets/images/industries/supply.svg";
import insights from "assets/images/industries/insights.svg";
import automotive from "assets/images/home/automotive.svg";
import core from "assets/images/home/core.svg";
import brain from "assets/images/home/ai.svg";
import economy from "assets/images/home/economy.svg";
import sustain from "assets/images/industries/sustain.svg";
import stack from "assets/images/research/stack.svg";
import urban from "assets/images/research/urban.svg";
import productivity from "assets/images/research/productivity.svg";
import ethic from "assets/images/research/ethic.svg";
import ribbon from "assets/images/research/ribbon.svg";
import culture from "assets/images/research/culture.svg";
import vr from "assets/images/research/vr.svg";
import metaverse from "assets/images/research/metaverse.svg";
import privacy from "assets/images/research/privacy.svg";

const data = [
  {
    header: {
      sections_header: "Key Focus Areas",
      case_study: "Case Study",
      case_study_content:
        "Exploring AI-driven solutions for achieving net-zero emissions in manufacturing by 2040.",
    },
    sections: [
      {
        image: research,
        title: "AI and Automation",
        description:
          "Understanding how AI is driving efficiency and innovation across industries.",
      },
      {
        image: core,
        title: "Sustainability Through AI",
        description:
          "Exploring AI’s role in fostering environmental responsibility.",
      },
      {
        image: brain,
        title: "Human-AI Collaboration",
        description:
          "Researching how AI and human intelligence can work together for better outcomes.",
      },
      {
        image: automotive,
        title: "Tech and Society",
        description:
          "Investigating the social, ethical, and economic impacts of AI on the future workforce.",
      },
    ],
  },
  {
    header: {
      title: "G Magic",
      subtitle: "Deep Green: Leveraging AI for a Sustainable Future",
      description:
        "DeepMagic’s Deep Green initiative focuses on the intersection of AI and sustainability. Our research delves into how AI can enable businesses to reduce their carbon footprints, optimize resource management, and create eco-friendly innovations that benefit both the environment and the economy",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "AI-driven predictive models are helping industries cut carbon emissions by 15% in the next decade by optimizing resource usage and energy management.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "AI for Climate Action",
        description:
          "How AI is being used to predict and mitigate the effects of climate change.",
      },
      {
        image: supply,
        title: "Sustainable Supply Chains",
        description:
          "Researching how AI can improve efficiency and sustainability in global supply chains.",
      },
      {
        image: insights,
        title: "Energy Efficiency",
        description:
          "Exploring AI solutions for reducing energy consumption in industrial and residential sectors.",
      },
      {
        image: economy,
        title: "Circular Economy",
        description:
          "Investigating AI’s role in driving the transition to a circular economy by optimizing waste management and resource recycling.",
      },
    ],
  },
  {
    header: {
      title: "Magic Cities",
      subtitle: "Building Future-Ready Cities with AI and Smart Technology",
      description:
        "DeepMagic’s Future-Ready Cities research explores how AI, IoT, and automation are transforming urban spaces into smart, sustainable environments. Our research examines how cities of the future can be more connected, resilient, and sustainable, using AI to improve quality of life and economic growth.",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "AI-powered city planning tools are helping municipalities reduce energy consumption in public buildings by 20%, while optimizing traffic and waste management systems.",
    },
    sections: [
      {
        image: research,
        title: "Smart Infrastructure",
        description:
          "How AI is optimizing urban infrastructure, from traffic systems to utilities.",
      },
      {
        image: urban,
        title: "AI for Urban Planning",
        description:
          "Researching AI-powered solutions for city planning, resource management, and public services.",
      },
      {
        image: stack,
        title: "Sustainability in Urban Areas",
        description:
          "Investigating how AI can help cities reduce pollution, conserve energy, and create sustainable living spaces.",
      },
      {
        image: automotive,
        title: "Citizen Engagement and Services",
        description:
          "Exploring AI-driven services that enhance citizen engagement, improve healthcare, and foster education",
      },
    ],
  },
  {
    header: {
      title: "Gen AI: The Magic World",
      subtitle: "Gen AI: Shaping the Future of Work in an AI-Driven World",
      description:
        "In our Gen AI research, DeepMagic investigates the implications of AI on the workforce, exploring how automation, machine learning, and AI-driven tools are reshaping industries and the future of work. Our research provides insights into how businesses can prepare for the new era of work while ensuring human collaboration with AI.",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "Businesses that integrate AI into their workforce strategies are seeing productivity gains of up to 35%, along with the creation of new roles centered around AI management and innovation.",
    },
    hasBackground: true,
    sections: [
      {
        image: core,
        title: "AI in Workforce Transformation",
        description:
          "How AI is automating jobs, creating new roles, and reshaping traditional industries.",
      },
      {
        image: brain,
        title: "Human-AI Collaboration",
        description:
          "Exploring how workers can collaborate effectively with AI systems to enhance productivity and innovation.",
      },
      {
        image: sustain,
        title: "Reskilling and Upskilling",
        description:
          "Researching how businesses can reskill employees for an AI-powered future.",
      },
      {
        image: productivity,
        title: "AI-Driven Productivity",
        description:
          "Investigating how AI is driving efficiency and innovation across industries.",
      },
    ],
  },
  {
    header: {
      title: "Deep Intactness",
      subtitle:
        "The Future of Us: Navigating AI s Impact on Society and Humanity",
      description:
        "DeepMagic’s The Future of Us research explores the social, ethical, and human dimensions of AI. As AI reshapes industries and daily life, our research focuses on how these changes will affect society, employment, governance, and the future of human identity in an increasingly AI-driven world.",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "AI is expected to disrupt over 30% of current job roles by 2030, requiring new strategies for workforce development and inclusive growth.",
    },
    sections: [
      {
        image: ethic,
        title: "AI and Ethics",
        description:
          "Investigating the ethical implications of AI adoption, including issues of privacy, bias, and decision-making.",
      },
      {
        image: brain,
        title: "Human Identity in the AI Era",
        description:
          "Exploring how AI influences human roles, relationships, and creativity.",
      },
      {
        image: ribbon,
        title: "AI and Governance",
        description:
          "Researching how governments and organizations can regulate AI to ensure it benefits society at large.",
      },
      {
        image: automotive,
        title: "The Social Impact of AI",
        description:
          "Understanding how AI will reshape work-life balance, societal roles, and access to opportunities.",
      },
    ],
  },
  {
    header: {
      title: "Business @ Deep",
      subtitle:
        "Future-Ready Business Benchmark: Measuring AI-Readiness in Today s Enterprises",
      description:
        "In The Future-Ready Business Benchmark research, DeepMagic analyzes how businesses across industries are preparing for AI-driven transformation. We develop benchmarks for evaluating an organization’s readiness to integrate AI into its operations, ensuring they can thrive in a tech-centric future.",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "Organizations that score high on AI-readiness benchmarks report 50% higher returns on innovation investments, indicating a strong link between AI integration and business success.",
    },
    hasBackground: true,
    sections: [
      {
        image: research,
        title: "AI-Readiness Indicators",
        description:
          "Defining metrics to assess a company’s preparedness for AI integration, from leadership to infrastructure.",
      },
      {
        image: stack,
        title: "Industry-Specific AI Strategies",
        description:
          "Researching how different industries are adopting AI and how they can accelerate their transformation.",
      },
      {
        image: culture,
        title: "AI and Organizational Culture",
        description:
          "Understanding how AI influences corporate culture, decision-making, and innovation.",
      },
      {
        image: automotive,
        title: "AI and Financial Performance",
        description:
          "Investigating the correlation between AI adoption and financial outcomes in future-ready businesses.",
      },
    ],
  },
  {
    header: {
      title: "The MagicVerse",
      subtitle:
        "DeepMeta: Unlocking New Realities with AI and Immersive Technologies",
      description:
        "DeepMagic’s The Metaverse research delves into the rapidly evolving world of virtual environments, exploring how AI and immersive technologies are converging to create new opportunities for business, entertainment, and social interaction. Our research highlights how the metaverse will change the way people work, play, and connect.",

      sections_header: "Key Research Areas",
      case_study: "Case Study",
      case_study_content:
        "The metaverse is projected to be a $1 trillion industry by 2030, with AI at the core of virtual reality, enabling seamless experiences, virtual workplaces, and immersive entertainment.",
    },
    sections: [
      {
        image: vr,
        title: "AI in Virtual Worlds",
        description:
          "Exploring how AI powers avatars, interactions, and economies in the metaverse.",
      },
      {
        image: metaverse,
        title: "Business in the Metaverse",
        description:
          "Investigating how companies can leverage the metaverse for brand engagement, marketing, and new revenue streams.",
      },
      {
        image: culture,
        title: "Social and Cultural Impact",
        description:
          "Understanding how the metaverse will reshape social interactions, cultural experiences, and virtual communities.",
      },
      {
        image: privacy,
        title: "Security and Privacy",
        description:
          "Researching AI-driven solutions for ensuring security, privacy, and trust in virtual environments.",
      },
    ],
  },
];

export default data;
