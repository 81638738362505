import React from "react";
import { Button, Header } from "semantic-ui-react";
import style from "./scss/homeScreen.module.scss";
import PageTemplate from "globals/components/PageTemplate";
import ai from "assets/images/home/ai.svg";
import automotive from "assets/images/home/automotive.svg";
import city from "assets/images/home/city.svg";
import cloud from "assets/images/home/cloud.svg";
import core from "assets/images/home/core.svg";
import green from "assets/images/home/deepGreen.svg";
import economy from "assets/images/home/economy.svg";
import healthcare from "assets/images/home/healthcare.svg";
import industry from "assets/images/home/industry.svg";
import research from "assets/images/home/research.svg";
import service from "assets/images/home/service.svg";

const HomeScreen = () => {
  const data = [
    {
      header: {
        title: "What We Do",
        subtitle: "AI-Driven Solutions for a Future-Ready Business",
        description:
          "DeepMagic offers cutting-edge AI solutions that help businesses navigate the complexities of the modern world. From AI-powered automation to sustainability strategies, our services empower organizations to innovate, optimize, and grow.",
        sections_header: "Key Areas",
      },
      hasBackground: true,
      sections: [
        {
          id: "services",
          title: "Services",
          description:
            "We provide AI solutions at all scales tailored to your needs, from Application and Cloud Solutions to Intelligent Process Automation and Security.",
          buttonText: "Explore Our Services",
          routeValue: "/services",
          buttonEnable: true,
          image: service,
        },
        {
          id: "industries",
          title: "Industries",
          description:
            "Across a wide range of industries, from Automotive and Banking to Healthcare and Retail, DeepMagic’s AI solutions transform workflows for all functional areas.",
          buttonText: "Discover Our Industry Solutions",
          buttonEnable: true,
          routeValue: "/industries",
          image: industry,
        },
        {
          id: "research",
          title: "Research",
          description:
            "Stay ahead of trends with DeepMagic’s cutting-edge research. Dive into sustainability, machine learning, and AI advancements.",
          buttonText: "Explore Our Research",
          buttonEnable: true,
          routeValue: "/research",
          image: research,
        },
      ],
    },
    {
      header: {
        title: "Services Highlight",

        description:
          "DeepMagic offers a wide range of AI-driven services that help organizations modernize, scale, and innovate. Whether you're looking to streamline operations, enhance customer experiences, or secure your digital infrastructure, we have the expertise to deliver.",
        sections_header: "Key Service Categories",
      },
      sections: [
        {
          id: "artificialIntelligence",
          title: "Artificial Intelligence",
          description:
            "Our core AI solutions drive innovation, automate processes, and provide predictive insights to transform businesses.",
          buttonEnable: false,
          image: ai,
        },
        {
          id: "cloudSolutions",
          title: "Cloud Solutions",
          description:
            "We help businesses move to the cloud, enabling scalability, flexibility, and cost savings.",
          buttonText: "Learn More About Our Services",
          routeValue: "/services",
          buttonEnable: true,
          image: cloud,
        },
        {
          id: "coreModernization",
          title: "Core Modernization",
          description:
            "Modernize legacy systems to be future-ready with AI-integrated technologies that ensure agility and performance.",
          buttonEnable: false,
          image: core,
        },
      ],
    },
    {
      header: {
        title: "Industry Expertise",
        subtitle: "Driving Transformation Across Industries",
        description:
          "At DeepMagic, we understand that every industry is unique. Our AI solutions are tailored to meet the specific challenges and opportunities within each vertical. From automotive to education, and from healthcare to retail, we bring deep expertise and cutting-edge technology to help industries thrive in a digital future.",
        sections_header: "Industry Highlights",
      },
      hasBackground: true,
      sections: [
        {
          id: "automotive",
          title: "Automotive",
          description:
            "Revolutionizing the future of mobility with autonomous driving and AI-powered manufacturing solutions.",
          buttonEnable: false,
          image: automotive,
        },
        {
          id: "banking",
          title: "Banking",
          description:
            "Improving fraud detection, risk management, and customer experiences with AI.",
          buttonText: "Explore Industry Solutions",
          routeValue: "/industries",
          buttonEnable: true,
          image: industry,
        },
        {
          id: "healthcare",
          title: "Healthcare",
          description:
            "Enhancing diagnostics, patient care, and operational efficiency through AI-driven solutions.",
          buttonEnable: false,
          image: healthcare,
        },
      ],
    },
    {
      header: {
        title: "Research & Insights",
        subtitle: "AI Research that Shapes the Future",
        description:
          "DeepMagic is not only a provider of AI solutions but also a leader in AI research. Our insights help businesses anticipate changes, embrace emerging technologies, and navigate complex landscapes with confidence.",
        sections_header: "Highlighted Research Areas",
      },
      sections: [
        {
          id: "deepGreen",
          title: "Deep Green",
          description:
            "AI solutions that foster sustainability and environmental responsibility.",
          buttonEnable: false,
          image: green,
        },
        {
          id: "futureReadyCities",
          title: "Future-Ready Cities",
          description:
            "Research on building smart, sustainable cities powered by AI and IoT.",
          buttonText: "Discover Our Research",
          routeValue: "/research",
          buttonEnable: true,
          image: city,
        },
        {
          id: "genAI_newWork_newWorld",
          title: "Gen AI: New Work, New World",
          description:
            "Insights on how AI is reshaping industries and the future of work.",
          buttonEnable: false,
          image: core,
        },
      ],
    },
    {
      header: {
        title: "Case Studies/Success Stories",
        subtitle: "Real Results, Powered by AI",
        description:
          "Explore how DeepMagic has partnered with businesses across industries to deliver measurable results using AI-powered solutions. Our case studies highlight the tangible impact of AI on operations, growth, and innovation.",
        sections_header: "Examples",
      },
      hasBackground: true,
      sections: [
        {
          id: "manufacturing",
          title: "Manufacturing",
          description:
            "Reducing downtime by 30% with AI-based predictive maintenance.",
          buttonEnable: false,
          image: automotive,
        },
        {
          id: "healthcare_caseStudy",
          title: "Healthcare",
          description:
            "Improving diagnostic accuracy by 25% using AI-powered imaging tools.",
          buttonText: "Explore Success Stories",
          buttonEnable: true,
          image: industry,
        },
        {
          id: "retail",
          title: "Retail",
          description:
            "Increasing customer engagement by 40% through AI-driven personalization.",
          buttonEnable: false,
          image: city,
        },
      ],
    },
    {
      header: {
        title: "Sustainability Commitment",
        subtitle: "AI for a Sustainable Future",
        description:
          "At DeepMagic, sustainability is at the core of our mission. Through AI innovations, we help businesses reduce their carbon footprints, optimize energy usage, and build more sustainable operations.",
        sections_header: "Key Sustainability Areas",
      },
      sections: [
        {
          id: "aiForEnergyEfficiency",
          title: "AI for Energy Efficiency",
          description: "Optimizing energy consumption across industries.",
          buttonEnable: false,
          image: core,
        },
        {
          id: "sustainableSupplyChains",
          title: "Sustainable Supply Chains",
          description:
            "Using AI to create eco-friendly, efficient supply chains.",
          buttonText: "Learn About Our Sustainability Initiatives",
          buttonEnable: true,
          image: city,
        },
        {
          id: "circularEconomy",
          title: "Circular Economy",
          description:
            "Enabling businesses to reduce waste and embrace sustainability through AI.",
          buttonEnable: false,
          image: economy,
        },
      ],
    },
    {
      header: {
        title: "Why DeepMagic?",
        subtitle: "Why Choose DeepMagic?",
        description:
          "DeepMagic is more than a technology provider – we are your partner in transformation. Our expertise in AI, combined with our commitment to innovation, sustainability, and excellence, makes us the ideal choice for businesses ready to embrace the future.",
        sections_header: "Key Differentiators",
      },
      hasBackground: true,
      sections: [
        {
          id: "deepExpertise",
          title: "Deep Expertise",
          description:
            "Decades of experience in AI, automation, and digital transformation.",
          buttonEnable: false,
          image: city,
        },
        {
          id: "industrySpecificSolutions",
          title: "Industry-Specific Solutions",
          description:
            "Tailored AI solutions designed to meet the unique needs of your industry.",
          buttonText: "Discover What Makes Us Different",
          buttonEnable: true,
          image: city,
        },
        {
          id: "sustainabilityDriven",
          title: "Sustainability-Driven",
          description:
            "Committed to leveraging AI to drive environmental sustainability and responsible growth.",
          buttonEnable: false,
          image: core,
        },
      ],
    },
    {
      header: {
        title: "Join Us",
        subtitle: "Be a Part of the AI Revolution",
        description:
          "At DeepMagic, we believe in the power of talent to shape the future. We’re always looking for passionate, innovative individuals to join our team and help us lead the AI revolution. Whether you're an experienced professional or just starting your career, there’s a place for you at DeepMagic.",
        sections_header: "Careers",
        buttonEnable: true,
        buttonText: "Explore Careers at DeepMagic",
        routeValue: "/careers",
      },
    },
    {
      header: {
        title: "News & Insights",
        subtitle: "Stay Updated with the Latest from DeepMagic",
        description:
          "Stay ahead of the curve with our latest news, insights, and press releases. From new AI solutions to groundbreaking research, DeepMagic is at the forefront of technological innovation and industry leadership.",
        sections_header: "Insights",
        buttonEnable: true,
        buttonText: "Read Our Latest News",
      },
    },
  ];

  const handleNavigate = (value) => {
    window.location.href = value;
  };

  return (
    <>
      <div className={style.homeContainer}>
        <div className={style.bannerSection}>
          <Header as="h1">
            Unlock the Power of AI to <br /> Transform Your Business
          </Header>
          <Header as="h2">
            DeepMagic delivers AI-powered innovation across
            <br /> industries, driving efficiency, sustainability, and growth.
          </Header>
          <div className={style.buttonSection}>
            <Button
              onClick={() => handleNavigate("/services")}
              className="silverBgbutton"
            >
              Our Services
            </Button>
            <Button
              className="silverBgbutton"
              onClick={() => handleNavigate("/research")}
            >
              Our Research
            </Button>
            <Button
              className="silverBgbutton"
              onClick={() => handleNavigate("/industries")}
            >
              Our Industry Expertise
            </Button>
          </div>
        </div>
        <div className="clr20" />
        <div className="clr20" />
        <div className="clr20" />
        <PageTemplate data={data} />
      </div>
    </>
  );
};

export default HomeScreen;
