/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import headerImage from "assets/images/HeaderRight.png";
import barOpen from "assets/images/barOpen.svg";
import barClose from "assets/images/barClose.svg";
import logo from "assets/images/logo.webp";
import style from "./scss/header.module.scss";
import {
  Button,
  Image,
  Menu,
  Segment,
  TransitionablePortal,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Header = ({ t }) => {
  let history = useHistory();
  const [activeItem, setActiveItem] = useState(
    window.location.href.split("/").pop()
  );
  const headerRef = useRef();
  const [open, setOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const menuList = [
    "home",
    "aboutUs",
    "services",
    "insights",
    "careers",
    "industries",
    "research",
  ];

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        headerRef.current.style.top = "-50%";
      } else {
        // if scroll up show the navbar
        headerRef.current.style.top = "0";
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (window.location.href.split("/").pop().length === 0) {
      setActiveItem("home");
    } else {
      setActiveItem(window.location.href.split("/").pop());
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.innerWidth > mediaBreakpoint.mobile
    ) {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleItemClick = (value) => {
    console.log(value);
    setActiveItem(value);
    setOpen(false);
    history.push(`/${value}`);
  };

  const renderMenu = () => (
    <>
      {React.Children.toArray(
        menuList.map((menuName) => (
          <>
            <Menu.Item
              className={style.menuName}
              name={t(menuName)}
              color={activeItem === menuName ? "teal" : "white"}
              active={activeItem === menuName}
              onClick={() => handleItemClick(menuName)}
            />
          </>
        ))
      )}
    </>
  );

  const renderResponsiveMenuSection = () => (
    <>
      <Menu className={style.menu} fluid vertical>
        {renderMenu()}
      </Menu>
    </>
  );

  const handleRedirect = () => {
    window.open("/", "_self");
  };

  return (
    <>
      <div ref={headerRef} className={style.headerWrapper}>
        <div className={style.header}>
          <div className={style.iocHeaderLogoWrapper}>
            <Image src={logo} className={style.iocHeaderLogo} />
          </div>
          <Menu className={style.headerMenuWrapper} secondary pointing>
            {React.Children.toArray(
              menuList?.map((menuName) => (
                <>
                  <Menu.Item
                    className={`${style.menuName} ${
                      activeItem === menuName ? style.activeMenu : ""
                    }`}
                    name={t(menuName)}
                    active={activeItem === menuName}
                    onClick={() => handleItemClick(menuName)}
                  />
                </>
              ))
            )}
          </Menu>
          <div className={style.signupBtnWrapper}>
            <Button
              className="silverBgbutton"
              content="Sign-Up"
              onClick={handleRedirect}
            />
          </div>
          <div className={open ? style.barIconClose : style.barIconOpen}>
            {!open ? (
              <>
                <img
                  onClick={() => setOpen(!open)}
                  src={barOpen}
                  alt="barOpen"
                />
              </>
            ) : (
              <>
                <img
                  onClick={() => setOpen(!open)}
                  src={barClose}
                  alt="barClose"
                />
              </>
            )}
          </div>
          <TransitionablePortal
            transition={{ animation: "fade left", duration: 250 }}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Segment className={style.menuWrapper}>
              {renderResponsiveMenuSection()}
            </Segment>
          </TransitionablePortal>
        </div>
        {/* <div
          className={`${
            lastScrollY < 100 ? style.headerRight : style.hideImage
          }`}
        >
          <img className={style.headerImg} src={headerImage} />
        </div> */}
      </div>
    </>
  );
};

export default withTranslation("common")(Header);
