import PageTemplate from "globals/components/PageTemplate";
import React from "react";
import { Card, Grid, GridColumn, Image } from "semantic-ui-react";
import style from "../industries/scss/industries.module.scss";
import servicesStyle from "./scss/services.module.scss";
import researchRightBanner from "assets/images/service/right-banner.webp";
import ai from "assets/images/service/aiSolutions.webp";
import cloud from "assets/images/service/cloud.webp";
import core from "assets/images/service/core.webp";
import ipa from "assets/images/service/ipa.webp";
import eps from "assets/images/service/eps.webp";
import security from "assets/images/service/security.webp";
import data from "./serviceData";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Services = () => {
  const cardData = [
    {
      id: 1,
      header: "Artificial Intelligence Solutions",
      description:
        "Our advanced AI technologies, including machine learning, computer vision, and natural language processing, are transforming how businesses operate--- optimizing processes, enhancing decision-making, and driving innovation.",
      image: ai,
    },
    {
      id: 2,
      header: "Cloud Solutions",
      description:
        "Migrate, optimize, and manage your operations in the cloud with our scalable, secure, and cost-effective cloud services that enable you to grow while maintaining flexibility and control.",
      image: cloud,
    },
    {
      id: 3,
      header: "Intelligent Process Automation",
      description:
        "Harness the power of AI to automate complex workflows, reduce manual tasks, and improve operational efficiency across all business processes.",
      image: ipa,
    },
    {
      id: 4,
      header: "Core Modernization",
      description:
        "Future-proof your business by modernizing legacy systems with AI and cloud-based solutions, ensuring they are agile, efficient, and capable of adapting to evolving demands.",
      image: core,
    },
    {
      id: 5,
      header: "Enterprise Platform Services",
      description:
        "From ERP to CRM systems, we design and implement AI-powered enterprise platforms that enhance collaboration, streamline operations, and improve customer engagement.",
      image: eps,
    },
    {
      id: 6,
      header: "Security Solutions",
      description:
        "Protect your business with AI-driven security systems that detect, prevent, and mitigate cyber threats in real-time, ensuring the safety of your data and operations.",
      image: security,
    },
  ];
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <>
      <div className={servicesStyle.serviceContainer}>
        <Grid
          className={servicesStyle.service_wrapper}
          verticalAlign="middle"
          stretched
        >
          <GridColumn computer={12} mobile={16} className={style.leftBanner}>
            <h1 className={style.industry_header}>
              Comprehensive AI Solutions to Transform Your Business
            </h1>
            <p className={style.description}>
              From cloud migration to intelligent process automation, our
              services empower your organization to innovate, scale, and thrive
              in a digitalfirst world
            </p>
          </GridColumn>
          {!isResponsive && (
            <>
              <Grid.Column computer={4} className={style.rightBanner}>
                <Image src={researchRightBanner} />
              </Grid.Column>
            </>
          )}
        </Grid>
        <div className={servicesStyle.informationContent}>
          <h1>AI-Powered Services for the Modern Enterprise</h1>
          <p>
            At DeepMagic, we deliver a wide range of AI-powered services
            designed to drive digital transformation and unlock new business
            opportunities. Whether you need to modernize legacy systems,
            automate processes, or implement cutting-edge AI applications, our
            solutions are built to meet the unique needs of your organization.
            We empower businesses to embrace the future with agility,
            scalability, and confidence.
          </p>
          <h3>Our Core Services</h3>
        </div>
        <Grid
          columns={isResponsive ? 1 : 3}
          container
          stretched
          className={servicesStyle.cardDetails}
        >
          {cardData.map((card) => (
            <Grid.Column key={card.id}>
              <Card fluid raised className={servicesStyle.cardContent}>
                <Image src={card.image} />
                <Card.Content textAlign="center">
                  <Card.Header>{card.header}</Card.Header>
                  <Card.Description>{card.description}</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
        <p className={servicesStyle.description}>
          With DeepMagic, your business has access to an integrated suite of AI
          services that drive transformation and unlock sustainable growth. We
          partner with you at every step of your AI journey to ensure you
          harness the full potential of technology for your business success.
        </p>
        <PageTemplate data={data} columnCount={4} />
      </div>
    </>
  );
};

export default Services;
