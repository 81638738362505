import React from "react";
import style from "./scss/industries.module.scss";
import { Container, Grid, GridColumn, Header, Image } from "semantic-ui-react";
import PageTemplate from "globals/components/PageTemplate";
import rightBanner from "assets/images/industries/right_banner.webp";
import data from "./industryData";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Industries = () => {
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <>
      <div className={style.industryContainer}>
        <Grid
          className={style.industries_wrapper}
          verticalAlign="middle"
          stretched
        >
          <GridColumn computer={12} mobile={16} className={style.leftBanner}>
            <h1 className={style.industry_header}>
              AI Solutions Tailored to Transform Your Industry
            </h1>
            <p className={style.description}>
              Empowering industries with innovative AI-driven solutions that
              optimize efficiency, enhance sustainability, and unlock new growth
              opportunities.
            </p>
          </GridColumn>
          {!isResponsive && (
            <>
              <GridColumn computer={4} className={style.rightBanner}>
                <Image src={rightBanner} />
              </GridColumn>
            </>
          )}
        </Grid>
        <Container className={style.subContainer} textAlign="center">
          <Header as="h1">
            Driving Innovation Across Every
            <br /> Industry
          </Header>
          <p>
            At DeepMagic, we understand that each industry faces unique
            challenges and opportunities in today’s rapidly evolving landscape.
            That’s why we deliver AI-powered solutions tailored to meet the
            specific needs of diverse sectors, from manufacturing and automotive
            to healthcare and banking. Our industry-focused approach helps
            businesses harness the power of AI to optimize processes, increase
            agility, and stay ahead in a competitive market.
          </p>
          <Header content="Key Industries We Serve" as="h2" />
        </Container>
        <PageTemplate columnCount={4} data={data} />
      </div>
    </>
  );
};

export default Industries;
